const newReferencesMigration = {
  version: 13,
  up: state => {
    try {
      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: [],
        },
        products: {
          ...state.products,
          products: [],
          displayedProducts: [],
          materials: [],
        },
        app: {
          ...state.app,
          version: 13,
        },
      }
    } catch (error) {
      console.error(error)
    }
  },
}

export default newReferencesMigration
