<template>
  <div class="information-section">
    <div class="section-header">
      <span>{{ $t('Billing_information') }}</span>
      <div class="section-header-actions">
        <div class="secondary-button" @click="dialogFormVisible = true">{{ $t('create_a_new_address') }}</div>
      </div>
    </div>

    <div class="section-body">
      <address-selector :addressType="AddressTypes.billing" />
    </div>

    <el-dialog :title="$t('create_a_new_address')" :visible.sync="dialogFormVisible" width="94%">
      <address-form :uid="null" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AddressTypes from '../enums/AddressTypes'

import AddressForm from './AddressForm.vue'
import AddressSelector from './AddressSelector.vue'

export default {
  components: {
    AddressForm, //
    AddressSelector,
  },

  data() {
    return {
      AddressTypes,
      dialogFormVisible: false,
    }
  },

  computed: {
    ...mapState({
      user: state => state.app.user,
    }),

    chosenAddress() {
      if (!this.user.addresses || !this.user.addresses.length || !this.form.addressUid) return null
      return this.user.addresses.find(({ uid }) => uid === this.form.addressUid)
    },
  },
}
</script>
