<template>
  <div id="product-list-wrapper" class="product-list-wrapper" @scroll="scrollEvent">
    <Loading v-if="loading" />

    <productFilters v-if="!loading" />

    <BrandSelector v-if="!loading && !isSortedOrFiltered" />

    <div v-if="!loading && isSortedOrFiltered" class="product-items-container">
      <div class="product-item" v-for="(product, i) in displayedProducts" :key="i">
        <product :product="product" />
      </div>
    </div>

    <div v-if="!loading && isSortedOrFiltered && !displayedProducts.length" class="no-products-section">
      <div>{{ $t('No_products_match_your_search') }}</div>
      <div>{{ $t('Try_clearing_some_filters') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import BrandSelector from '../components/BrandSelector.vue'
import Product from '../components/Product'
import ProductFilters from '../components/ProductFilters'
import Loading from '../components/Loading'

export default {
  components: {
    Product, //
    BrandSelector,
    ProductFilters,
    Loading,
  },

  computed: {
    ...mapState({
      displayedProducts: state => state.products.displayedProducts,
      loading: state => state.products.loading,
    }),

    ...mapGetters({
      isSortedOrFiltered: 'app/isSortedOrFiltered',
    }),
  },

  data: () => {
    return {
      showScrollToTop: false,
    }
  },

  methods: {
    scrollEvent(e) {
      this.showScrollToTop = e.target.scrollTop - 300 < 0
    },
  },

  watch: {
    showScrollToTop(oldVal, newVal) {
      if (oldVal !== newVal) this.$store.commit('app/setShowScrollToTop', newVal)
    },
  },

  async created() {
    await this.$store.dispatch('products/initialize')
    this.$store.dispatch('cart/initialize')
  },
}
</script>
<style lang="scss">
.product-list-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - var(--top-menu-height));
  overflow-y: auto;
  overflow-x: hidden;

  .no-products-section {
    font-size: var(--big);
    text-align: center;
    width: 100%;
  }

  .product-items-container {
    position: relative;
    right: 5px;
    display: grid;
    grid-auto-columns: 167px;
    grid-gap: 20px;
    margin-bottom: 22px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 167px);
    justify-content: center;
  }

  @media only screen and (max-width: 600px) {
    .product-items-container {
      grid-template-columns: repeat(2, 167px);
    }
  }

  .product-item {
    display: inline-block;
    height: 269px;
    align-items: center;
    flex-direction: column;
    color: var(--dark);
    text-align: center;
    font-size: 12px;
  }
}
</style>
