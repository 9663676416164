<template>
  <div class="product-edit-wrapper">
    <div v-if="product" class="product-edit-inner-wrapper">
      <div class="product-edit-header">
        <img class="brand-image" :src="GetBrandImage(product.brand)" />
        <div class="navigation-buttons-group">
          <router-link :to="`/product/${previousProduct}/edit`"> <i class="el-icon-arrow-left fat-icon"/></router-link>
          <router-link :to="`/product/${nextProduct}/edit`"><i class="el-icon-arrow-right fat-icon"/></router-link>
          <router-link :to="`/products`"><i class="el-icon-close fat-icon"/></router-link>
        </div>
      </div>

      <div class="product-edit-body">
        <div class="product-edit-first-body">
          <el-carousel :interval="5000" :arrow="product.pictures.length > 1 ? 'always' : 'never'" height="200px">
            <el-carousel-item
              v-for="(fileName, i) in product.pictures"
              :key="`product-${product.reference}-picture-id-${i}`"
            >
              <product-image :fileName="fileName" :clickable="true" />
            </el-carousel-item>
          </el-carousel>

          <div class="product-name">{{ product.name }}</div>

          <div class="preview-price">
            <product-prices
              v-if="product.prices.length"
              :product="product"
              :quantity="quantity"
              :selected-material="selectedMaterial"
            />
          </div>

          <div class="params-selector">
            <div v-if="product.prices.length" class="select-material">
              <el-select v-model="selectedMaterial" @change="onSelectedMaterialChanged" autocomplete="disabled">
                <el-option
                  v-for="material in product.materials"
                  :key="material"
                  :label="$t(getMaterialNameFromMaterialCode(material))"
                  :value="material"
                />
              </el-select>
            </div>

            <div v-if="product.prices.length" class="select-count">
              <el-input-number v-model="quantity" :min="1" :max="100" />
            </div>
          </div>
        </div>

        <div class="product-edit-second-body">
          <div class="select-color">
            <div class="colors-title">{{ $t('Colors') }}</div>
            <div class="color-name">
              <span v-if="chosenColorName">{{ chosenColorName }}</span>
            </div>
            <div class="colors-panel">
              <div :class="['inner-panel', showAllColors ? '' : 'mini-color-preview']">
                <div
                  :class="[
                    'color-out-box',
                    selectedColorReference === reference ? 'active-color-box' : '',
                    'clickable',
                  ]"
                  v-for="{ reference, cssColor } in displayedColors"
                  :key="reference"
                >
                  <div @click="onColorSelected(reference)" class="color-box" :style="getColorBoxStyle(cssColor)"></div>
                </div>
              </div>
              <i
                @click="showAllColors = !showAllColors"
                :class="[showAllColors ? 'el-icon-arrow-up' : 'el-icon-arrow-down', 'click-to-unfold clickable']"
              />
            </div>
          </div>

          <div class="other-info">
            <div>
              <span>{{ $t('Quantity_of_holds') }}: {{ product.shapeNumber }}</span>
            </div>
            <div>
              <span>{{ $t('Size') }}: {{ product.size }}</span>
            </div>
            <div>
              <span>{{ $t('Grip') }}: {{ product.shapeTypes.join(', ') }}</span>
            </div>
            <div>
              <span>{{ $t('Screw quantity') }}: {{ product.screwNumber }}</span>
            </div>
            <div>
              <span>{{ $t('Bolt quantity') }}: {{ product.boltQuantity }}</span>
            </div>
            <div>{{ $t('bolts_and_screws_not_included') }}</div>
          </div>

          <div class="button-wrapper">
            <div v-if="!product.prices.length" class="click-to-switch">
              {{ $t('AVAILABLE_SOON') }}
            </div>
            <div v-else-if="!isUpdating" class="call-to-action" @click="onAddToCart">{{ $t('ADD_TO_CART') }}</div>
            <div v-else class="call-to-action" @click="onUpdateCartItem">{{ $t('UPDATE') }}</div>
          </div>
        </div>

        <div v-if="!previousProduct">{{ previousProduct }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { buildFinalReference } from '../services/ReferencesService'
import { DEFAULT_SELECTED_COLOR_REFERENCE } from '../constants/Parameters'
import { getMaterialNameFromMaterialCode } from '../services/Materials'
import { getTotalPriceInCents } from '../services/MathService'

import CartItem from '../models/CartItem'
import GetBrandImage from '../services/GetBrandImage'
import ProductImage from './ProductImage'
import ProductPrices from './ProductPrices.vue'

export default {
  components: { ProductImage, ProductPrices },

  data: () => {
    return {
      showAllColors: false,
      quantity: 1,
      selectedColorReference: DEFAULT_SELECTED_COLOR_REFERENCE,
      selectedMaterial: '',
      isUpdating: false,
      productToUpdate: null,
      GetBrandImage,
      getMaterialNameFromMaterialCode,
      previousProduct: null,
      nextProduct: null,
      pictureIds: null,
    }
  },

  computed: {
    ...mapState({
      products: state => state.products.products,
      colors: state => state.products.colors,
      currency: state => state.products.pricelist.currency,
    }),

    ...mapGetters({
      product: 'app/getSelectedProduct',
    }),

    displayedColors() {
      return this.colors.filter(
        ({ materials }) => materials.indexOf(getMaterialNameFromMaterialCode(this.selectedMaterial)) > -1,
      )
    },

    chosenColorName() {
      if (!this.selectedColorReference) return null
      return this.colors.find(({ reference }) => reference === this.selectedColorReference).name
    },

    finalReference() {
      return buildFinalReference(this.product.reference, this.selectedMaterial, this.selectedColorReference)
    },
  },

  methods: {
    getColorBoxStyle(cssColor) {
      const style = { backgroundColor: cssColor }
      if (cssColor === '#fff') style.boxShadow = `inset 0px 0px 0px 1px grey`
      return style
    },

    onColorSelected(reference) {
      this.selectedColorReference = reference
    },

    onAddToCart() {
      const cartItem = this.getCartItem()
      this.$store.dispatch('cart/addCartItem', cartItem)
      this.$router.push({ path: `/product/${this.product.reference}` })
    },

    onUpdateCartItem() {
      const cartItem = this.getCartItem()
      this.$store.dispatch('cart/updateCartItem', { oldItemReference: this.productToUpdate, newItem: cartItem })
      this.$router.push({ path: `/product/${this.product.reference}` })
    },

    getCartItem() {
      const shapeReference = this.product.reference
      const parentReference = `${shapeReference}-${this.selectedMaterial.substring(0, 2)}`
      const unitPrice = this.product.prices.find(({ parent }) => parent === parentReference).price

      if (unitPrice === undefined) {
        throw new Error('A product unit price can not be undefined')
      }

      const cartItem = CartItem.create({
        reference: this.finalReference,
        parentReference,
        shapeReference: this.product.reference,
        quantity: this.quantity,
        unitPrice,
        totalPrice: getTotalPriceInCents(this.quantity, unitPrice),
        currency: this.currency,
        discount: 0,
        discountedTotalPrice: 0,
      })

      return cartItem
    },

    onSelectedMaterialChanged() {
      const { materials } = this.colors.find(({ reference }) => reference === this.selectedColorReference)

      if (materials.indexOf(this.selectedMaterial) === -1) {
        this.selectedColorReference = this.colors[0].reference
      }
    },

    setProductsNavigationData() {
      const productReferences = this.$store.state.products.displayedProducts.map(({ reference }) => reference)

      const currentIndex = productReferences.indexOf(this.product.reference)
      const productReferencesLastIndex = productReferences.length - 1

      const previousIndex = currentIndex === 0 ? productReferencesLastIndex : currentIndex - 1
      const nextIndex = currentIndex === productReferencesLastIndex ? 0 : currentIndex + 1

      this.previousProduct = productReferences[previousIndex]
      this.nextProduct = productReferences[nextIndex]
    },
  },

  async created() {
    if (screen.width > 600) this.showAllColors = true

    if (!this.products.length) {
      await this.$store.dispatch('products/initialize')
    }

    const { color, material, quantity, update, reference } = this.$route.query

    if (color) {
      this.selectedColorReference = color
      const colors = [...this.colors]
      colors.length = 4
      const defaultColorReferences = colors.map(({ reference }) => reference)
      if (!defaultColorReferences.includes(color)) this.showAllColors = true
    }

    if (material) {
      this.selectedMaterial = material
    } else {
      this.selectedMaterial = this.product.materials[0]
    }

    if (quantity) this.quantity = quantity
    if (update) this.isUpdating = update
    if (reference) this.productToUpdate = reference

    this.setProductsNavigationData()
  },
}
</script>
<style lang="scss">
.el-carousel__item {
  .image-container img {
    width: 200px;
    height: 200px;
  }
}

.brand-image {
  height: 50px;
  margin: 20px;
}
.product-edit-body {
  width: auto;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 2fr auto;
}

.product-edit-first-body {
  width: auto;
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  text-align: center;
}
.product-edit-second-body {
  width: auto;
  display: grid;
  grid-auto-flow: auto;
}

.product-edit-wrapper {
  height: unset;
  max-height: calc(100% - 61px);
  background: var(--transparent);
  position: relative;
  width: 100%;
  overflow-y: auto;

  .button-wrapper {
    margin: 20px 0;
  }

  .product-edit-inner-wrapper {
    border-radius: 15px;
    background: white;
    margin: 20px;
  }

  .product-edit-header {
    clear: both;
    display: block;
    width: 100%;
    position: relative;
    height: 100px;
    text-align: center;
  }

  .navigation-buttons-group {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
  }

  .fat-icon {
    color: var(--lighter);
    text-align: right;
    cursor: pointer;
    font-weight: 900;
    font-size: 32px;
  }

  .el-carousel {
    .el-carousel__arrow {
      font-size: var(--big);
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
    .el-carousel__indicators {
      display: none;
    }
  }

  .product-name {
    margin-top: 17px;
    font-size: var(--big);
    font-weight: bold;
    color: var(--accent);
  }

  .product-type {
    margin-top: 14px;
    font-size: 12px;
    color: var(--lighter);
  }

  .params-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    justify-self: center;
    column-gap: 25px;
    align-self: start;
  }

  .el-dropdown {
    width: 141px;
    height: 31px;
    background: #ffffff;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 16px;
    line-height: 31px;

    .el-icon-arrow-down:before {
      color: var(--primary);
      font-weight: bold;
    }
  }

  .el-input-number {
    width: 141px;
    height: 31px;
    background: #ffffff;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    overflow: hidden;

    .el-input-number__decrease,
    .el-input-number__increase {
      background-color: transparent;
      border: none;
      color: var(--primary);
    }

    input,
    .el-input,
    .el-input-number__decrease,
    .el-input-number__increase {
      height: 100%;
      border: none;
    }
  }

  .origin-price {
    color: var(--lighter);
    position: relative;
    display: inline-block;
    margin-top: 18px;

    &:after {
      display: block;
      content: '';
      background-color: var(--lighter);
      width: 110%;
      height: 1px;
      transform: rotate(-15deg);
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 6px;
    }
  }

  .real-price {
    font-size: var(--big);
    font-weight: bold;
    color: var(--accent);
    margin-top: 11px;
  }

  .colors-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    color: var(--dark);
    margin-top: 14px;
  }

  .color-name {
    color: var(--lighter);
    font-size: 12px;
    margin-top: 10px;
  }

  .colors-panel {
    width: 236px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 18px;
  }

  .inner-panel {
    box-sizing: border-box;
    padding: 15px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 55px);
    grid-auto-rows: 55px;
  }

  .mini-color-preview {
    overflow: hidden;
    max-height: 70px;
  }

  .select-color {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 18px;
  }

  .color-box {
    width: 36px;
    height: 36px;
    background-color: red;
    display: inline-block;
  }

  .color-out-box {
    display: grid;
    place-items: center;
  }

  .active-color-box {
    border: 1px solid var(--light);
  }

  i {
    font-size: var(--big);
    color: var(--primary);
  }
  .click-to-unfold {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .other-info {
    text-align: left;
    color: var(--dark);
    margin-top: 30px;
    padding: 0px 25px;

    span {
      display: inline-block;
      width: 50%;
    }

    div {
      margin-top: 8px;
    }
  }

  .button-wrapper {
    display: grid;
    place-items: center;
    height: 74px;
  }

  .not-valid-button {
    line-height: 54px;
    margin: 36px auto 0 auto;
    font-weight: bold;
    color: var(--white);
    font-size: var(--big);
    width: 246px;
    height: 54px;
    background: var(--lighter);
    opacity: 1;
    border-radius: 27px;
  }

  .preview-price {
    margin: 24px 0;
  }

  .discounted-prices {
    margin-top: 0;
    font-size: var(--big);
    font-weight: bold;
    line-height: 24px;
    color: var(--accent);
  }
}

.select-material {
  input {
    border: none;
    width: 141px;
    height: 31px;
    background: #ffffff;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 16px;
  }
  .el-input {
    .el-input__suffix .el-input__icon {
      line-height: 31px;
      color: var(--primary);
    }
  }
}

.el-select-dropdown {
  width: 140px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 12px !important;

  .popper__arrow {
    display: none !important;
  }
  .el-select-dropdown__item.selected {
    color: var(--primary);
  }
}

@media only screen and (max-width: 600px) {
  .product-edit-wrapper {
    max-height: calc(100% - 61px);
    background: #e5e5e5;
    position: relative;
    width: 100%;
    overflow-y: auto;
    height: unset;

    .product-edit-inner-wrapper {
      display: grid;
      border-radius: 15px;
      background: white;
      margin: 20px;
      grid-auto-flow: row;
      grid-auto-flow: row;
      grid-template-columns: unset;

      .product-edit-header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 50px;
        align-content: center;
        place-items: center;
        margin-top: 8px;

        .brand-image {
          height: 36px;
          margin: 0;
        }

        .navigation-buttons-group {
          position: unset;
          margin: 0;
          place-self: end;
          align-self: center;
          margin-right: 16px;
        }
      }
    }
    .product-edit-body {
      width: auto;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
    }

    .select-color {
      margin-top: 0;
    }
  }
  .nextPage :hover {
    margin-left: 100px;
  }
}
</style>
