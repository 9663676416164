<template>
  <div class="cart-items-wrapper">
    <cart-item :key="index" v-for="(items, index) in cartItems" :cart-items-dto="items" :editable="true" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartItem from '../components/CartItem'

export default {
  components: {
    CartItem,
  },

  computed: {
    ...mapGetters({
      cartItems: 'cart/cartItemsMappedByReference',
    }),
  },
}
</script>
<style lang="scss">
.cart-items-wrapper {
  display: grid;
  place-items: center;
}
</style>
