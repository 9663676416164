<template>
  <div class="product-prices-container">
    <div :class="isDiscounted ? 'cart-items-total-prices' : ''">
      <span :class="isDiscounted ? 'struck' : ''">{{ total }}</span>
      <span v-if="isDiscounted" class="discount-percentage">&nbsp; {{ discount }}</span>
    </div>
    <div class="discounted-prices" v-if="isDiscounted">{{ discountedTotal }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatPriceAsString, formattedDiscount } from '../services/formatting'

export default {
  props: ['cartItems'],

  computed: {
    ...mapState({
      currency: state => state.app.user.currency,
    }),

    ...mapGetters({
      referenceTotalPrice: 'cart/referenceTotalPrice',
      referenceDiscountedTotalPrice: 'cart/referenceDiscountedTotalPrice',
      referenceDiscountInPercentage: 'cart/referenceDiscountInPercentage',
    }),

    isDiscounted() {
      return this.referenceDiscountInPercentage(this.productReference)
    },

    total() {
      const totalPrice = this.referenceTotalPrice(this.productReference)
      return formatPriceAsString(totalPrice, this.currency)
    },

    discount() {
      const discount = this.referenceDiscountInPercentage(this.productReference)
      return formattedDiscount(discount)
    },

    discountedTotal() {
      const price = this.referenceDiscountedTotalPrice(this.productReference)
      return formatPriceAsString(price, this.currency)
    },

    productReference() {
      return this.cartItems[0].shapeReference
    },
  },
}
</script>

<style scoped>
.prices {
  color: var(--light);
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: normal;
}
.product-prices-container {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
}
.cart-items-total-prices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  margin-right: 8px;
}
</style>
