<template>
  <div class="page-wrapper my-information-wrapper">
    <div class="page-header">
      <div class="page-title">{{ $t('My_information') }}</div>
    </div>

    <div class="page-body">
      <user-information v-if="user" :user="user" />

      <div class="information-section" v-if="user">
        <div class="section-header">
          <span>{{ $t('My_addresses') }}</span>
          <div class="section-header-actions">
            <div class="secondary-button" @click="dialogFormVisible = true">{{ $t('create_a_new_address') }}</div>
          </div>
        </div>

        <div class="section-body">
          <address-list />
        </div>
      </div>

      <el-dialog :title="$t('create_a_new_address')" :visible.sync="dialogFormVisible" width="94%">
        <address-form :uid="null" />
      </el-dialog>

      <el-dialog :title="$t('Warning')" :visible.sync="showCompleteInfoDialog" width="30%" center>
        <span>{{ $t('Complete_information_prompt') }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showCompleteInfoDialog = false">{{ $t('OK') }}</el-button>
        </span>
      </el-dialog>

      <div v-if="userCountry" class="primary-button" @click="$router.push({ path: '/products' })">
        {{ $t('go_to_the_catalogue') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AddressList from '../components/AddressList'
import AddressForm from '../components/AddressForm'
import UserInformation from '../components/UserInformation'

export default {
  components: {
    AddressList,
    AddressForm,
    UserInformation,
  },

  data() {
    return {
      dialogFormVisible: false,
      showCompleteInfoDialog: false,
    }
  },

  computed: mapState({
    user: state => state.app.user,
    userCountry: state => state.app.user.country,
  }),

  async created() {
    if (this.$route.params.showCompleteInfoPrompt) {
      this.showCompleteInfoDialog = true
      const query = { ...this.$route.query }
      delete query.showCompleteInfoDialog
      this.$router.replace({ path: '/my-information' })
    }

    await this.$store.dispatch('app/setUserInfo')
  },
}
</script>
<style lang="scss">
.my-information-wrapper {
  box-sizing: border-box;
  .page-body {
    margin: 46px auto 0 auto;
    width: 330px;
  }
  .information-section {
    margin: unset;
    min-width: 330px;
    line-height: 23px;
  }
  .section-header {
    margin: 0 auto;
    height: 23px;
    background: #55595d;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 600px) {
  .my-information-wrapper {
    display: block !important;
    max-width: unset;

    .information-section,
    .page-body {
      max-width: unset;
      display: block !important;
      width: 100%;
    }
  }
}
</style>
