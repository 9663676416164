<template>
  <div class="login-wrapper">
    <img :src="KastlineLogo" alt="Kastline logo" class="kastline-logo" />
    <div class="login-input-container mt-40">
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-position="top"
        onsubmit="event.preventDefault()"
        @keyup.enter.native="onFormSubmitted"
      >
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" :type="passwordFieldType" :placeholder="$t('password')">
            <i
              slot="suffix"
              title="Show password"
              @click="togglePasswordFieldType"
              style="cursor: pointer"
              class="el-icon-view"
            />
          </el-input>
        </el-form-item>
      </el-form>

      <div class="call-to-action" @click="onFormSubmitted()">Set new password</div>
      <div class="secondary-button mt-30" @click="$router.push({ path: '/login' })">Login with Magic Link</div>
    </div>
  </div>
</template>

<script>
import KastlineLogo from '../../public/images/logo/kastline_logo.png'

export default {
  data() {
    return {
      KastlineLogo,

      loginForm: {
        password: '',
      },

      passwordFieldType: 'password',

      rules: {
        password: [
          { required: true, message: 'Please input a password', trigger: 'blur' },
          { min: 2, max: 100, message: 'The password should be 2 to 100 characters long', trigger: 'blur' },
        ],
      },
    }
  },

  methods: {
    async onFormSubmitted() {
      this.$refs['loginForm'].validate(async valid => {
        try {
          if (valid) {
            const { password } = this.loginForm
            const { token, email } = this.$route.params

            await this.$store.dispatch('app/setPassword', { email, password, token })
            this.$router.push({ path: '/password-login' })
          } else {
            throw new Error('Something went wrong...')
          }
        } catch (error) {
          console.error(error)
        }
      })
    },

    togglePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
<style lang="scss">
.login-wrapper,
.login-input-container,
.login-failed-container,
.check-your-inbox-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-wrapper {
  .kastline-logo {
    margin-top: 50px;
    max-width: 290px;
  }

  .svg-magic {
    margin-top: 70px;
  }

  .lines-container {
    margin-top: 30px;

    div {
      width: 206px;
      height: 17px;
      background: var(--lighter);
      margin-bottom: 6px;
    }
  }

  input {
    margin-top: 0px;
    width: 246px;
    height: 25px;
    background: #ffffff;
    border: 1px solid var(--dark);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding-left: 20px;
  }

  .svg-check-your-inbox {
    margin-top: 135px;
  }

  .friendly-tips {
    margin-top: 55px;
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    color: var(--dark);
  }

  .svg-magic-link-fail {
    margin-top: 50px;
  }

  .login-failed-container {
    .lines-container {
      margin-top: 99px;
    }
  }
}
</style>
