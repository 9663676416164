<template>
  <div class="address-display-wrapper">
    <div class="section-detail-title">{{ address.name }}</div>
    <div class="buttons-section" v-if="!disableEdit">
      <div class="secondary-button" @click="$emit('on-edit-address', address.uid)">{{ $t('Edit') }}</div>
      <span class="separator">&nbsp;|&nbsp;</span>
      <div class="secondary-button" @click="$emit('on-delete-address', address.uid)">{{ $t('Delete') }}</div>
    </div>
    <div v-if="names" class="section-detail">{{ names }}</div>
    <div v-if="phone1" class="section-detail">{{ phone1 }}</div>
    <div v-if="phone2" class="section-detail">{{ phone2 }}</div>
    <div class="section-detail">{{ address.street1 }}</div>
    <div v-if="address.street2" class="section-detail">{{ address.street2 }}</div>
    <div class="section-detail">{{ address.city }}, {{ address.postCode }}</div>
    <div class="section-detail">{{ location }}</div>
    <div v-if="address.notes" class="section-detail">{{ address.notes }}</div>
  </div>
</template>

<script>
export default {
  props: ['address', 'disableEdit'],

  computed: {
    names() {
      const values = [this.address.companyName, `${this.address.lastName} ${this.address.firstName}`]
      return values.filter(x => x).join(', ')
    },

    location() {
      const values = [this.address.state, this.address.country]
      return values.filter(x => x).join(', ')
    },

    phone1() {
      const { phone1, phone1CountryCode } = this.address
      if (!phone1) return false
      return phone1CountryCode ? `(${phone1CountryCode}) ${phone1}` : phone1
    },

    phone2() {
      const { phone2, phone2CountryCode } = this.address
      if (!phone2) return false
      return phone2CountryCode ? `(${phone2CountryCode}) ${phone2}` : phone2
    },
  },
}
</script>

<style lang="scss">
.address-display-wrapper {
  .buttons-section {
    margin: 6px 0;
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 4px;
    align-items: center;

    .separator {
      color: var(--primary);
      font-size: var(--small);
    }
  }
}
</style>
