import Brands from '../enums/Brands'

import agrippLogo from '../../public/images/Brands/agripp_logo.jpg'
import arcticLogo from '../../public/images/Brands/arctic_logo.webp'
import artlineLogo from '../../public/images/Brands/artline-logo.png'
import boazLogo from '../../public/images/Brands/boaz-logo.jpg'
import caiLogo from '../../public/images/Brands/cai-logo.png'
import cheetaLogo from '../../public/images/Brands/cheeta-logo.svg'
import comfyLogo from '../../public/images/Brands/comfy-logo.jpg'
import expressionLogo from '../../public/images/Brands/expression-logo.png'
import incurvoLogo from '../../public/images/Brands/incurvo-logo.png'
import kongLogo from '../../public/images/Brands/kong_logo.png'
import motifLogo from '../../public/images/Brands/motif-logo.png'
import neoLogo from '../../public/images/Brands/neo-logo.jpg'
import olioLogo from '../../public/images/Brands/olio-logo.jpg'
import prosetLogo from '../../public/images/Brands/proset-logo.png'
import pusherLogo from '../../public/images/Brands/pusher-logo.png'
import rockcityLogo from '../../public/images/Brands/rockcity-logo.jpg'
import snapLogo from '../../public/images/Brands/snap-logo.png'
import staxLogo from '../../public/images/Brands/stax-logo.jpg'
import stokedLogo from '../../public/images/Brands/stoked-logo.webp'
import supperLogo from '../../public/images/Brands/supper-logo.webp'
import trangoLogo from '../../public/images/Brands/trango-logo.png'
import underBlueLogo from '../../public/images/Brands/under-blue-logo.png'
import uprisingLogo from '../../public/images/Brands/uprising-logo.jpg'

const GetBrandImage = brand => {
  const theBrand = brand.toLowerCase().replace(/\s/g, '')

  if (theBrand === Brands.agripp) return agrippLogo
  if (theBrand === Brands.arctic) return arcticLogo
  if (theBrand === Brands.artline) return artlineLogo
  if (theBrand === Brands.boaz) return boazLogo
  if (theBrand === Brands.cai) return caiLogo
  if (theBrand === Brands.cheeta) return cheetaLogo
  if (theBrand === Brands.comfy) return comfyLogo
  if (theBrand === Brands.expression) return expressionLogo
  if (theBrand === Brands.incurvo) return incurvoLogo
  if (theBrand === Brands.kong) return kongLogo
  if (theBrand === Brands.motif) return motifLogo
  if (theBrand === Brands.neo) return neoLogo
  if (theBrand === Brands.olio) return olioLogo
  if (theBrand === Brands.proset) return prosetLogo
  if (theBrand === Brands.pusher) return pusherLogo
  if (theBrand === Brands.rockcity) return rockcityLogo
  if (theBrand === Brands.snap) return snapLogo
  if (theBrand === Brands.stax) return staxLogo
  if (theBrand === Brands.stoked) return stokedLogo
  if (theBrand === Brands.supper) return supperLogo
  if (theBrand === Brands.trango) return trangoLogo
  if (theBrand === Brands.underblue) return underBlueLogo
  if (theBrand === Brands.uprising) return uprisingLogo

  return null
}

export default GetBrandImage
