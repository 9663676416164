import Materials from '../enums/Materials'

export const getMaterialNameFromMaterialCode = materialCode => {
  if (materialCode.substring(0, 2) === 'G8') return Materials.GRPDT
  const firstChar = materialCode.charAt(0)
  if (firstChar === 'E') return Materials.PE
  if (firstChar === 'U') return Materials.PU
  if (firstChar === 'G') return Materials.GRP
  return ''
}
