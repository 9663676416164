import Vue from 'vue'

const state = {
  orders: [],
  orderItems: {},
  orderAddresses: {},
}

const mutations = {
  setOrders(state, orders) {
    state.orders = orders
  },

  setOrderItems(state, { orderUid, orderItems }) {
    Vue.set(state.orderItems, orderUid, orderItems)
  },

  setOrderAddresses(state, { orderUid, orderAddresses }) {
    Vue.set(state.orderAddresses, orderUid, orderAddresses)
  },
}

const getters = {
  getOrderByUid: state => uid => {
    return state.orders.find(order => order.uid === uid)
  },

  getOrderAddresses: state => uid => {
    return state.orderAddresses[uid] || []
  },

  getOrderItems: state => uid => {
    return state.orderItems[uid] || []
  },
}

const actions = {
  async refreshOrders({ rootState, commit }) {
    try {
      const orders = await rootState.dependencies.backEndRepository.getOrders()
      commit('setOrders', orders)
    } catch (error) {
      console.error(error)
    }
  },

  async setOrderData({ rootState, commit }, orderUid) {
    try {
      const repository = rootState.dependencies.backEndRepository

      const orderItems = await repository.getOrderItems(orderUid)
      commit('setOrderItems', { orderUid, orderItems })

      const orderAddresses = await repository.getOrderAddresses(orderUid)
      commit('setOrderAddresses', { orderUid, orderAddresses })
    } catch (error) {
      console.error(error)
    }
  },

  reset({ commit }) {
    commit('setOrders', [])
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
