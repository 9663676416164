import Vue from 'vue'
import Filters from '../enums/Filters'
import router from '../plugins/vueRouter'

const defaultState = {
  version: 0,
  user: {},
  bearerToken: '',
  cartOrProducts: 'products',
  userAddresses: {},
  selectedReference: null,
  showScrollToTop: false,
  userCountry: null,
}

const state = {
  version: defaultState.version,
  user: defaultState.user,
  bearerToken: defaultState.bearerToken,
  // @TODO: Dirty fix
  cartOrProducts: defaultState.cartOrProducts,
  userAddresses: defaultState.userAddresses,
  selectedReference: defaultState.selectedReference,
  showScrollToTop: defaultState.showScrollToTop,
  userCountry: defaultState.userCountry,
  sortBy: {
    field: null,
    direction: null,
  },
  [Filters.material]: [],
  [Filters.shape]: [],
  [Filters.size]: [],
  [Filters.brand]: [],
  [Filters.texture]: [],
}

const mutations = {
  setCartOrProducts(state, cartOrProducts) {
    state.cartOrProducts = cartOrProducts
  },
  setUserInfo(state, userInfo) {
    state.user = userInfo
  },
  setSelectedReference(state, reference) {
    state.selectedReference = reference
  },
  setShowScrollToTop(state, bool) {
    state.showScrollToTop = bool
  },
  setUserAddresses(state, addresses) {
    if (!addresses.length) {
      Vue.delete(state.userAddresses, state.user.uid)
      return
    }

    Vue.set(state.userAddresses, state.user.uid, addresses)
  },

  setSortBy(state, sortBy) {
    Vue.set(state.sortBy, 'field', sortBy.field)
    Vue.set(state.sortBy, 'direction', sortBy.direction)
  },

  clearSortBy(state) {
    Vue.set(state.sortBy, 'field', null)
    Vue.set(state.sortBy, 'direction')
  },

  addFilter(state, { filter, value }) {
    state[Filters[filter]].push(value)
  },

  removeFilter(state, { filter, value }) {
    const currentValues = [...state[Filters[filter]]]
    const newValues = currentValues.filter(v => v !== value)
    Vue.set(state, Filters[filter], newValues)
  },

  clearFilters(state) {
    Vue.set(state, Filters.material, [])
    Vue.set(state, Filters.shape, [])
    Vue.set(state, Filters.size, [])
    Vue.set(state, Filters.brand, [])
    Vue.set(state, Filters.texture, [])
  },

  setBearerToken(state, token) {
    state.bearerToken = token
  },

  setUserCountry(state, country) {
    state.userCountry = country
  },
}

const getters = {
  getSelectedProduct: (state, getters, rootState) => {
    if (!state.selectedReference) return null
    return rootState.products.products.find(({ reference }) => reference === state.selectedReference)
  },

  userAddresses: state => {
    const { uid } = state.user
    return state.userAddresses[uid] || []
  },

  isSortedOrFiltered: state => {
    if (state.sortBy.field) return true

    for (let filter in Filters) {
      if (state[Filters[filter]].length) return true
    }

    return false
  },

  allActiveFilters: state => {
    return [
      ...state[Filters.material], //
      ...state[Filters.shape],
      ...state[Filters.size],
      ...state[Filters.brand],
      ...state[Filters.texture],
    ]
  },

  filtersByCategory: state => {
    return {
      [Filters.material]: state[Filters.material],
      [Filters.shape]: state[Filters.shape],
      [Filters.size]: state[Filters.size],
      [Filters.brand]: state[Filters.brand],
      [Filters.texture]: state[Filters.texture],
    }
  },
}

const actions = {
  async initialize({ dispatch }) {
    dispatch('setBearerToken')
    await dispatch('setUserInfo')
  },

  async setUserInfo({ commit, dispatch, rootState }) {
    try {
      dispatch('setBearerToken')
      const userInfo = await rootState.dependencies.backEndRepository.getUserInfo()
      commit('setUserInfo', userInfo)

      if (!userInfo.country) {
        router.push({ path: '/my-information/showCompleteInfoPrompt=true' })
        return
      }

      dispatch('products/initialize', null, { root: true })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  },

  async setUserAddresses({ commit, rootState }) {
    const userAddresses = await rootState.dependencies.backEndRepository.getUserAddresses()
    commit('setUserAddresses', userAddresses)
  },

  async postAddress({ rootState }, form) {
    const repository = rootState.dependencies.backEndRepository
    await repository.postAddress(form)
  },

  async deleteAddressByUid({ state, rootState, commit }, uid) {
    if (rootState.cart.shippingAddressUid === uid) commit('cart/setShippingAddressUid', '', { root: true })
    if (rootState.cart.billingAddressUid === uid) commit('cart/setBillingAddressUid', '', { root: true })
    const newAddresses = [...state.userAddresses[state.user.uid]].filter(address => address.uid !== uid)
    commit('setUserAddresses', newAddresses)
    const repository = rootState.dependencies.backEndRepository
    await repository.deleteAddressByUid(uid)
  },

  async updateUserInfo({ rootState, dispatch }, userInfo) {
    const repository = rootState.dependencies.backEndRepository
    await repository.updateUserInfo(userInfo)
    await dispatch('setUserInfo')
  },

  async passwordLogin({ rootState, commit }, { email, password }) {
    const repository = rootState.dependencies.backEndRepository
    const token = await repository.login(email, password)
    commit('setUserCountry', null)
    commit('setBearerToken', token)
  },

  async logout({ rootState, dispatch }) {
    const repository = rootState.dependencies.backEndRepository
    await repository.logout()
    dispatch('resetState')
  },

  resetState({ dispatch }) {
    dispatch('resetLocalState')
    dispatch('cart/reset', null, { root: true })
    dispatch('orders/reset', null, { root: true })
    dispatch('products/reset', null, { root: true })
  },

  resetLocalState({ commit }) {
    commit('setCartOrProducts', defaultState.cartOrProducts)
    commit('setUserInfo', {})
    commit('setSelectedReference', defaultState.selectedReference)
    commit('setShowScrollToTop', defaultState.showScrollToTop)
    commit('setUserAddresses', defaultState.userAddresses)
    commit('setBearerToken', defaultState.bearerToken)
    commit('setUserCountry', defaultState.userCountry)
  },

  async resetPassword({ rootState }, email) {
    const repository = rootState.dependencies.backEndRepository
    await repository.resetPassword(email)
  },

  async setPassword({ rootState }, { email, token, password }) {
    const repository = rootState.dependencies.backEndRepository
    await repository.setPassword(email, token, password)
  },

  async register({ rootState }, { email, password, country }) {
    const repository = rootState.dependencies.backEndRepository
    await repository.register(email, password, country)
  },

  async validateEmail({ rootState }, { email, token }) {
    const repository = rootState.dependencies.backEndRepository
    await repository.validateEmail(email, token)
  },

  setBearerToken({ state, rootState }) {
    if (state.bearerToken) {
      rootState.dependencies.backEndRepository.client.setBearerToken(state.bearerToken)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
