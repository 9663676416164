<template>
  <div class="order-wrapper">
    <div class="order-header">
      <span>{{ orderDate }}</span>
    </div>

    <div class="order-body">
      <div class="text-section">{{ $t('Total_amount') }}: {{ orderAmount }}</div>
      <div class="image-section">
        <img :src="draft" :alt="order.status" />
        <span class="order-status">{{ $t(order.status) }}</span>
      </div>
    </div>

    <div class="order-footer">
      <div class="primary-button" @click="$router.push({ path: `/orders/detail/${order.uid}` })">See details</div>
    </div>
  </div>
</template>

<script>
import draft from '../../public/images/icons/draft.svg'
import processing from '../../public/images/icons/processing.svg'
import delivered from '../../public/images/icons/delivered.svg'
import moment from 'moment'

export default {
  props: ['order'],

  data() {
    return {
      draft,
      processing,
      delivered,
    }
  },

  computed: {
    orderDate() {
      return moment(this.order.createdAt).format('L')
    },

    orderAmount() {
      const amount = Number(Number(this.order.discountedTotalInBaseCurrency) / 100).toLocaleString()
      return `${this.order.currency} ${amount}`
    },
  },
}
</script>

<style lang="scss">
.order-wrapper {
  width: 355px;
  background: var(--white);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 24px 25px 34px 34px;
  margin-bottom: 20px;
  overflow: hidden;
  font-size: var(--normal);
  display: grid;
  grid-auto-flow: row;

  .order-header {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    background: var(--light);
    border-radius: 24px 25px 0 0;
    padding: 0 17px;
    display: grid;
    align-items: center;
    font-size: var(--medium);
    font-weight: bold;
    color: var(--white);
    text-align: center;
  }

  .order-body {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    place-items: center;
    padding-top: 16px;

    .text-section {
      justify-self: left;
      margin-left: 20px;
      font-weight: bold;
    }

    .image-section {
      display: grid;
      grid-auto-flow: row;

      img {
        place-self: center;
      }

      .order-status {
        text-transform: capitalize;
        font-weight: bold;
      }
    }
  }
}
</style>
