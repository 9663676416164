<template>
  <div class="page-wrapper">
    <div class="page-header">
      <div class="page-title">
        {{ $t('My_orders') }}
      </div>
    </div>
    <div class="page-body">
      <order v-for="order in orders" :key="order.uid" :order="order" />
      <div v-if="!orders.length" class="no-orders-section">
        <div>{{ $t('No_orders_yet') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Order from '../components/Order.vue'

export default {
  components: { Order },
  computed: {
    ...mapState({ orders: state => state.orders.orders }),
  },

  created() {
    try {
      this.$store.dispatch('orders/refreshOrders')
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.no-orders-section {
  text-align: left;
  font-weight: 700;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .page-wrapper {
    margin: unset;
  }
}
</style>
