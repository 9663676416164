const state = {
  showImagePreview: false,
  previewImageUrl: '',
}

const mutations = {
  setImagePreviewShow(state, previewImageUrl) {
    state.showImagePreview = true
    state.previewImageUrl = previewImageUrl
  },
  setImagePreviewHide(state) {
    state.showImagePreview = false
    state.previewImageUrl = ''
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
