<template>
  <div class="login-wrapper">
    <img :src="KastlineLogo" alt="Kastline logo" class="kastline-logo" />
    <div class="login-input-container mt-40">
      <div class="login-description">
        <div class="headline">Choose your country and browse the store as a guest!</div>
      </div>

      <el-form :model="userCountryForm" :rules="rules" ref="userCountryForm" label-position="top">
        <el-form-item label-width="120px" prop="country">
          <el-select
            v-model="userCountryForm.country"
            filterable
            :no-match-text="$t('No_matching_result')"
            :placeholder="$t('Choose_a_country')"
            autocomplete="disabled"
            style="width: 100%"
            @change="onFormValidation"
          >
            <el-option v-for="code in CountryCodes" :key="`c${code}`" :label="$t(code)" :value="code" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import KastlineLogo from '../../public/images/logo/kastline_logo.png'
import CountryCodes from '../enums/CountryCodes'

export default {
  data() {
    return {
      KastlineLogo,
      CountryCodes,

      userCountryForm: {
        country: '',
      },

      rules: {
        country: [
          { required: true, message: 'Please input your country', trigger: 'blur' },
          { min: 2, max: 100, message: 'The country should be 2 to 100 characters long', trigger: 'blur' },
        ],
      },
    }
  },

  computed: {
    ...mapState({
      userCountry: state => state.app.userCountry,
    }),
  },

  methods: {
    onFormValidation() {
      this.$refs['userCountryForm'].validate(async valid => {
        if (!valid) return
        this.$store.dispatch('app/resetState')
        this.$store.commit('app/setUserCountry', this.userCountryForm.country)
        this.$router.push({ path: '/products' })
      })
    },
  },

  async created() {
    if (this.userCountry) {
      this.userCountryForm.country = this.userCountry
    }
  },
}
</script>
