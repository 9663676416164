import TickerToSymbol from '../enums/TickerToSymbol'

export const gripsFromShapeTypes = shapeTypes => {
  if (!shapeTypes.length) return ''
  const shapeTypesAsString = shapeTypes.join(', ')
  if (shapeTypesAsString.length > 15) return `${shapeTypesAsString.substring(0, 15)}...`
  return shapeTypesAsString
}

const isInt = n => n % 1 === 0

export const formattedDiscount = discount => {
  if (!discount) return 'N/A'

  const discountAsPercent = discount / 100

  if (isInt(discountAsPercent)) return `${discountAsPercent.toLocaleString()}%`

  return `${discountAsPercent.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}%`
}

export const formatPriceAsString = (price, ticker) => {
  const priceInUnits = price / 100
  const toLocaleStringOptions = isInt(priceInUnits)
    ? {}
    : {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
  return `${TickerToSymbol[ticker]}${priceInUnits.toLocaleString(undefined, toLocaleStringOptions)}`
}
