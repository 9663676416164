<template>
  <div class="product-detail-wrapper" @click.prevent="">
    <div class="product-detail-inner-wrapper">
      <div class="product-detail-header">
        <i @click="onCloseClicked" class="el-icon-close"></i>
      </div>

      <div class="product-detail-product-block">
        <product-detail-product />
      </div>

      <div v-if="theOrderedItems.length" class="product-detail-set-list">
        <product-detail-set
          class="product-detail-set"
          v-for="(item, index) in orderedItems(product.reference)"
          :key="`s${item.reference}`"
          :set="item"
          :set-index="index"
        />
      </div>

      <div v-else class="product-detail-set-list">
        <div class="">{{ $t('Did_not_add_this_product_to_cart', { productName: product.name }) }}</div>
        <div class="primary-button" @click="$router.push({ path: `/product/${product.reference}/edit` })">
          {{ $t('ADD_MORE') }}
        </div>
      </div>

      <div class="bottom-container">
        <div class="total-amount">
          <div>{{ $t('Total amount') }}</div>
          <div class="right-price">
            <cart-item-total-prices :cart-items="theOrderedItems" />
          </div>
        </div>
        <div class="buttons-section">
          <div class="primary-button" @click="$router.push({ path: `/product/${product.reference}/edit` })">
            {{ $t('ADD_MORE') }}
          </div>
          <div class="secondary-button" @click="onCloseClicked">{{ goBackButtonText() }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CartItemTotalPrices from './CartItemTotalPrices.vue'
import ProductDetailProduct from './ProductDetailProduct'
import ProductDetailSet from './ProductDetailSet'

export default {
  components: { CartItemTotalPrices, ProductDetailProduct, ProductDetailSet },

  data() {
    return {
      theOrderedItems: [],
    }
  },

  computed: {
    ...mapGetters({
      orderedItems: 'cart/itemsByReference',
      product: 'app/getSelectedProduct',
    }),
  },

  methods: {
    onCloseClicked() {
      this.$router.push({ path: `/${this.$store.state.app.cartOrProducts}` })
    },

    goBackButtonText() {
      return this.$store.state.app.cartOrProducts === 'cart'
        ? this.$t('go_back_to_your_cart')
        : this.$t('go_back_to_the_catalogue')
    },
  },

  created() {
    try {
      this.theOrderedItems = this.orderedItems(this.product.reference)
    } catch (error) {
      this.$router.push({ path: '/products' })
    }
  },
}
</script>

<style lang="scss">
.product-card-wrapper {
  position: relative;
}

.bottom-container {
  .buttons-section {
    text-align: center;
    margin-bottom: 20px;
  }
}

.product-detail-inner-wrapper {
  display: grid;
  grid-template-rows: auto 245px auto;
  grid-template-columns: 1fr 1fr;
  border-radius: 15px;
  background: white;
  margin: 20px;
  place-items: center;

  .product-detail-header {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
    position: relative;
    height: 50px;
    display: grid;
    place-items: center;

    .el-icon-close {
      position: absolute;
      right: 20px;
      top: 20px;
      color: var(--lighter);
      text-align: right;
      cursor: pointer;
      font-weight: 900;
      font-size: 32px;
    }
  }

  .product-detail-product-block {
    place-self: normal;
  }
}

.product-detail-set-list {
  display: grid;
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 2;
  width: 330px;
  margin-top: 20px;
  align-self: start;
}

.color-block-head {
  font-size: var(--medium);
  color: white;
  height: 36px;
  width: 100%;
  line-height: 36px;
  background-color: var(--light);
  font-weight: bold;
}

.product-detail-wrapper {
  height: unset;
  max-height: calc(100% - 61px);
  background: var(--transparent);
  position: relative;
  width: 100%;
  overflow-y: auto;

  .product-edit-inner-wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
    border-radius: 15px;
    background: transparent;
    margin: 20px;
  }

  .product-detail-card {
    margin: 0 0 50px 0;
    width: 329px;
    height: 164px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 23px;
    overflow: hidden;
  }

  .product-main-body {
    display: flex;
    flex-direction: row;
  }

  .describe {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 12px;

    .size {
      margin-top: 12px;
      color: var(--dark);
    }

    .prices {
      margin-top: 8px;
    }

    .discounted-prices {
      margin-top: 6px;
    }
  }

  .select-index-block {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: var(--medium);
    font-weight: bold;
    line-height: 17px;
    color: var(--dark);
    right: 0;
    top: 0;
    content: '';
    position: absolute;
    width: 33px;
    height: 69px;
    background: var(--primary);
    box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-radius: 0 21px 0 20px;
    z-index: 1;
  }

  .product-detail-set {
    position: relative;
    margin-bottom: 9px;

    .right-price {
      width: 100px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .prices {
      color: var(--lighter);
    }

    .discounted-prices {
      font-size: 15px;
      font-weight: bold;
      color: var(--accent);
      margin-top: 6px;
    }
  }

  .origin-price {
    color: var(--lighter);
    position: relative;
    display: inline-block;
    margin-top: 18px;

    &:after {
      display: block;
      content: '';
      background-color: var(--lighter);
      width: 110%;
      height: 1px;
      transform: rotate(-15deg);
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 6px;
    }
  }

  .real-price {
    font-size: 15px;
    font-weight: bold;
    color: var(--accent);
    margin-top: 11px;
  }

  .bottom-container {
    padding-top: 10px;
    width: 100%;
  }
  .total-amount {
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 27px;
    text-align: center;

    .right-price {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 28px;
    }

    .real-price {
      font-size: var(--big);
      margin-top: 6px;
    }

    .product-prices-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .prices {
      color: var(--dark);
    }

    .discounted-prices {
      font-size: var(--big);
      font-weight: bold;
      color: var(--accent);
    }
  }
}

@media only screen and (max-width: 800px) {
  .product-detail-inner-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    border-radius: unset;

    .product-detail-set-list {
      display: grid;
      grid-row-start: unset;
      grid-row-end: unset;
      grid-column-start: unset;
      width: 330px;
      margin: 40px auto 0 auto;
    }
  }
}
</style>
