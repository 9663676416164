<template>
  <div class="order-wizard-wrapper">
    <div class="order-wizard-stepper-wrapper">
      <el-steps :active="activeStep" finish-status="success" align-center color="var(--secondary)">
        <el-step :title="$t('Information')"></el-step>
        <el-step :title="$t('Products')"></el-step>
        <el-step :title="$t('Confirmation')"></el-step>
      </el-steps>
    </div>

    <div class="order-wizard-body" v-if="activeStep === steps.INFORMATION">
      <!-- <user-information /> -->
      <shipping-information />
      <billing-information />
      <additional-information />
      <div class="buttons-section">
        <div
          :class="`primary-button ${!isInformationStepValid ? 'disabled-primary-button' : ''}`"
          @click="isInformationStepValid ? onInformationStepValidated() : ''"
        >
          {{ $t('Confirm') }}
        </div>
        <div class="secondary-button centered" @click="$router.go(-1)">{{ $t('Go_back') }}</div>
      </div>
    </div>

    <div class="order-wizard-body" v-if="activeStep === steps.PRODUCTS">
      <div class="cart-recap-wrapper">
        <cart-items />
        <cart-totals />
        <div class="cart-buttons">
          <div class="call-to-action" @click="onOrderSent">
            {{ $t('Confirm_and_send_order') }}
          </div>
          <div class="secondary-button" @click="activeStep = steps.INFORMATION">{{ $t('Go_back') }}</div>
        </div>
      </div>
    </div>

    <div class="order-wizard-body" v-if="activeStep >= steps.CONFIRMATION">
      <div class="order-wizard-confirmation-wrapper" v-if="isErrored">
        <div class="icon-wrapper">
          <img :src="errorIcon" />
        </div>
        <div class="headline">{{ $t('Something_went_wrong') }}</div>
        <div class="message">
          <span>Please try again or contact our team at sales@kastline.com</span>
        </div>
      </div>
      <div class="order-wizard-confirmation-wrapper" v-else>
        <div class="icon-wrapper">
          <img :src="successIcon" />
        </div>
        <div class="headline">{{ $t('Order_successfully_placed') }}</div>
        <div class="message">
          <span>Our team will contact you soon!</span>
        </div>
        <div class="button-wrapper">
          <div class="primary-button" @click="$router.push({ path: '/orders' })">{{ $t('See_my_orders') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import successIcon from '../../public/images/icons/success.svg'
import errorIcon from '../../public/images/icons/error.svg'

// import UserInformation from '../components/UserInformation'
import AdditionalInformation from '../components/AdditionalInformation'
import BillingInformation from '../components/BillingInformation'
import CartItems from '../components/CartItems'
import CartTotals from '../components/CartTotals'
import ShippingInformation from '../components/ShippingInformation'

const steps = {
  INFORMATION: 0,
  PRODUCTS: 1,
  CONFIRMATION: 2,
}

export default {
  components: {
    // UserInformation, //
    ShippingInformation,
    BillingInformation,
    AdditionalInformation,
    CartItems,
    CartTotals,
  },

  data() {
    return {
      steps,
      activeStep: steps.INFORMATION,
      isErrored: false,
      successIcon,
      errorIcon,
    }
  },

  computed: {
    ...mapState({
      billingAddressUid: state => state.cart.billingAddressUid,
      cartItems: state => state.cart.cartItems,
      currency: state => state.app.user.currency,
      incoterm: state => state.cart.incoterm,
      notes: state => state.cart.notes,
      shippingAddressUid: state => state.cart.shippingAddressUid,
      shipmentMode: state => state.cart.shipmentMode,
      packingType: state => state.cart.packingType,
    }),

    isInformationStepValid() {
      if (this.activeStep !== steps.INFORMATION) return true
      return this.shippingAddressUid && this.billingAddressUid && this.incoterm
    },
  },

  methods: {
    onInformationStepValidated() {
      this.activeStep = steps.PRODUCTS
    },

    async onOrderSent() {
      try {
        const payload = {
          shippingAddressUid: this.shippingAddressUid,
          billingAddressUid: this.billingAddressUid,
          baseCurrency: this.currency,
          currency: this.currency,
          incoterm: this.incoterm,
          notes: this.notes,
          cartItems: this.cartItems,
          shipmentMode: this.shipmentMode,
          packingType: this.packingType,
        }

        const repository = this.$store.state.dependencies.backEndRepository
        await repository.postCart(payload)
        this.$store.dispatch('cart/reset')
        this.isErrored = false
        this.activeStep = 4
      } catch (error) {
        this.isErrored = true
        this.activeStep = 4
        console.error(error)
      }
    },
  },

  created() {
    if (this.activeStep === steps.INFORMATION) {
      this.$store.dispatch('app/setUserAddresses')
    }
  },
}
</script>

<style lang="scss">
.el-step__head.is-success,
.el-step__head.is-process {
  color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}

.el-step__title.is-success,
.el-step__title.is-process {
  color: var(--dark) !important;
}

.el-step__head.is-wait,
.el-step__title.is-wait {
  color: var(--light) !important;
  border-color: var(--light) !important;
}

.order-wizard-wrapper {
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  width: 100%;
  display: inline-grid;
  height: calc(100% - var(--top-menu-height));
  overflow-y: scroll;
  overflow-x: hidden;
  align-content: start;

  .order-wizard-stepper-wrapper {
    padding-top: 20px;
    width: 100%;
    max-width: 600px;
  }

  .centered {
    text-align: center;
  }

  .order-wizard-body {
    width: 100%;
    display: grid;
    place-content: center;

    .cart-recap-wrapper {
      margin-top: 20px;
      display: grid;
      place-items: center;

      .cart-buttons {
        margin: 32px 0;
        text-align: center;

        .secondary-button {
          margin-top: 24px;
        }
      }
    }

    .buttons-section {
      margin-bottom: 20px;
    }
  }

  .order-wizard-confirmation-wrapper {
    display: grid;
    place-items: center;
    grid-template-rows: 120px 1fr;

    .headline {
      font-weight: bold;
      font-size: var(--big);
    }
    .message {
      margin: 30px 0;
    }
  }
}
.button-wrapper {
  width: 100%;
}
</style>
