<template>
  <div class="login-wrapper">
    <img :src="KastlineLogo" alt="Kastline logo" class="kastline-logo" />
    <div class="login-input-container mt-40">
      <div class="login-description">
        <div class="headline">{{ $t('welcome_to_kastline_online_store') }}</div>
      </div>
    </div>

    <div class="login-input-container mt-40">
      <router-link :to="`/login`">
        <div class="call-to-action">{{ $t('Login') }}</div>
      </router-link>

      <div class="secondary-button mt-30" @click="$router.push({ path: '/register' })">
        {{ $t('create_an_account_now_in_less_than_1_minute') }}
      </div>

      <div class="login-as-a-guest">
        <router-link to="/guest-login">{{ $t('explore_as_a_guest') }} | </router-link>
        <router-link to="/forgot-password">{{ $t('forgot_password') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import KastlineLogo from '../../public/images/logo/kastline_logo.png'

export default {
  data() {
    return {
      KastlineLogo,
    }
  },
}
</script>
