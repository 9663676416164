import { formatPriceAsString } from './formatting'
import { getMaterialNameFromMaterialCode } from './Materials'
import Filters from '../enums/Filters'
import FilterToField from '../enums/FilterToField'
import SortingDirections from '../enums/SortingDirections'
import SortingFields from '../enums/SortingFields'

class ProductsService {
  static addPricesToProducts(products, prices) {
    return products.map(product => {
      const productPrices = prices.filter(price => price.shape === product.reference)

      const cheapest = [...productPrices].reduce((acc, prices) => {
        if (acc === 0) return prices.price
        if (prices.price < acc) return prices.price
        return acc
      }, 0)

      return { ...product, prices: productPrices, cheapest }
    })
  }

  static getDiscountedDisplayPrice(prices, ticker, discount, quantity = 1, chosenMaterialCode = null) {
    const priceArray = this.getPrices(prices)
    if (!priceArray.length) return null

    const discountMultiplier = this.getDiscountMultiplier(discount)

    return prices
      .filter(price => {
        if (price.price < 0) return false
        if (!chosenMaterialCode) return true
        const materialCode = price.parent.split('-').pop()
        return materialCode === chosenMaterialCode
      })
      .map(price => {
        const priceInCents = price.price * quantity * discountMultiplier
        return formatPriceAsString(priceInCents, ticker)
      })
      .join(' - ')
  }

  static getDiscountMultiplier(discount) {
    return Number(((10_000 - discount) / 10_000).toFixed(2))
  }

  static getDisplayPrice(prices, ticker, quantity = 1) {
    const priceArray = this.getPrices(prices)
    if (!priceArray.length) return 'N/A'
    return priceArray
      .map(price => {
        const priceInCents = price * quantity
        return formatPriceAsString(priceInCents, ticker)
      })
      .join(' - ')
  }

  static getDisplayPriceWithMaterials(prices, ticker, quantity = 1, chosenMaterialCode = null) {
    if (!prices.length) return 'N/A'

    return prices
      .filter(price => {
        if (price.price < 0) return false
        if (!chosenMaterialCode) return true
        const materialCode = price.parent.split('-').pop()
        return materialCode === chosenMaterialCode
      })
      .sort((a, b) => a.price - b.price)
      .map(price => {
        const totalPriceInCents = price.price * quantity
        return formatPriceAsString(totalPriceInCents, ticker)
      })
      .join(' - ')
  }

  static getPrices(prices) {
    if (!prices.length) return []

    return prices
      .map(price => price.price || null)
      .filter(x => x)
      .sort((a, b) => a - b)
  }

  static getAttributesFromProducts(products, attributesArray) {
    const attributesObject = {}

    attributesArray.forEach(attribute => (attributesObject[attribute] = new Set()))

    products.forEach(product => {
      Object.entries(product).forEach(([property, value]) => {
        if (attributesArray.includes(property)) {
          if (value instanceof Array) {
            value.forEach(v => attributesObject[property].add(v))
            return
          }
          attributesObject[property].add(value)
        }
      })
    })

    Object.keys(attributesObject).forEach(key => (attributesObject[key] = [...attributesObject[key]].sort()))
    return attributesObject
  }

  static sortProducts(products, field, direction) {
    if (field === SortingFields.name) {
      if (direction === SortingDirections.ASC) {
        products.sort((a, b) => (a[field] >= b[field] ? 1 : -1))
      }

      if (direction === SortingDirections.DESC) {
        products.sort((a, b) => (a[field] <= b[field] ? 1 : -1))
      }

      return
    }

    if (field === SortingFields.price) {
      if (direction === SortingDirections.ASC) {
        products.sort((a, b) => a.cheapest - b.cheapest)
      }

      if (direction === SortingDirections.DESC) {
        products.sort((a, b) => b.cheapest - a.cheapest)
      }

      return
    }
  }

  static filterProducts(products, filtersByCategory) {
    let filteredProducts = [...products]

    const materialFilters = filtersByCategory[Filters.material]
    const shapeFilters = filtersByCategory[Filters.shape]
    const sizeFilters = filtersByCategory[Filters.size]
    const brandFilters = filtersByCategory[Filters.brand]
    const textureFilters = filtersByCategory[Filters.texture]

    if (materialFilters.length) {
      filteredProducts = filteredProducts.filter(product => {
        const values = product[FilterToField[Filters.material]]
        const valuesAsMaterial = values.map(getMaterialNameFromMaterialCode)
        return materialFilters.some(filter => valuesAsMaterial.some(val => val === filter))
      })
    }

    if (shapeFilters.length) {
      filteredProducts = filteredProducts.filter(product => {
        const value = product[FilterToField[Filters.shape]]
        return shapeFilters.map(f => f.toLowerCase()).some(filter => value.some(val => val === filter))
      })
    }

    if (sizeFilters.length) {
      filteredProducts = filteredProducts.filter(product => {
        const value = product[FilterToField[Filters.size]]
        return sizeFilters.includes(value)
      })
    }

    if (brandFilters.length) {
      filteredProducts = filteredProducts.filter(product => {
        const value = product[FilterToField[Filters.brand]].toLowerCase().replace(/\s/g, '')
        return brandFilters.map(b => b.toLowerCase().replace(/\s/g, '')).includes(value)
      })
    }

    if (textureFilters.length) {
      filteredProducts = filteredProducts.filter(product => {
        const value = product[FilterToField[Filters.texture]]
        return textureFilters.includes(value)
      })
    }

    return filteredProducts
  }
}

export default ProductsService
