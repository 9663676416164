<template>
  <div class="address-selector-wrapper">
    <div v-if="!addresses || !addresses.length">
      You must add an address to your profile to be able to place an order.
      <div class="primary-button" @click="dialogFormVisible = true">{{ $t('Create_an_address_now') }}</div>
    </div>

    <div v-else>
      <el-form :model="form" :rules="rules" ref="addressForm" label-position="top">
        <el-form-item label="Address" label-width="120px" prop="addressUid">
          <el-select
            v-model="form.addressUid"
            @change="onAddressChosen"
            :placeholder="$t('Choose_an_address')"
            autocomplete="disabled"
          >
            <el-option
              v-for="address in addresses"
              :key="`aa${address.name}`"
              :label="address.name"
              :value="address.uid"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div v-if="chosenAddress">
        <div class="chosen-address-descriptor">{{ chosenAddressText }}:</div>
        <address-display
          :address="chosenAddress"
          @on-edit-address="onEditAddress(chosenAddress.uid)"
          @on-delete-address="onDeleteAddress(chosenAddress.uid)"
          :disableEdit="false"
        />
      </div>
    </div>

    <el-dialog :title="$t('create_a_new_address')" :visible.sync="dialogFormVisible" width="94%" max-width="600px">
      <address-form :uid="selectedAddressUid" />
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import AddressTypes from '../enums/AddressTypes'

import AddressForm from './AddressForm.vue'
import AddressDisplay from './AddressDisplay.vue'

export default {
  props: ['addressType'],

  components: {
    AddressForm, //
    AddressDisplay,
  },

  data() {
    return {
      dialogFormVisible: false,
      selectedAddressUid: '',
      form: {
        addressUid: '',
      },
      rules: {
        addressUid: [{ required: true, message: 'Please select an address', trigger: 'change' }],
      },
    }
  },

  computed: {
    ...mapState({
      cart: state => state.cart,
    }),

    ...mapGetters({
      addresses: 'app/userAddresses',
    }),

    chosenAddress() {
      if (!this.addresses || !this.addresses.length || !this.form.addressUid) return null
      return this.addresses.find(({ uid }) => uid === this.form.addressUid)
    },

    chosenAddressText() {
      if (this.addressType === AddressTypes.shipping) return this.$t('Chosen_shipping_address')
      return this.$t('Chosen_billing_address')
    },
  },

  methods: {
    onEditAddress(uid) {
      this.selectedAddressUid = uid
      this.dialogFormVisible = true
    },

    async onDeleteAddress(uid) {
      this.$confirm(this.$t('this_will_permanently_delete_this_address_continue'), this.$t('Warning'), {
        confirmButtonText: this.$t('OK'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
      })
        .then(async () => {
          await this.deleteAddress(uid)
        })
        .catch(() => {
          /* nothing */
        })
    },

    async deleteAddress(uid) {
      try {
        await this.$store.dispatch('app/deleteAddressByUid', uid)
      } catch (error) {
        console.error(error)
      } finally {
        this.$router.go(0)
      }
    },

    onAddressChosen(uid) {
      this.propagateAddressChange(uid)
    },

    propagateAddressChange(uid) {
      const action = this.addressType === AddressTypes.shipping ? 'setShippingAddressUid' : 'setBillingAddressUid'
      this.$store.commit(`cart/${action}`, uid)
    },
  },

  mounted() {
    Vue.nextTick(() => {
      if (!this.addresses || !this.addresses.length) return
      const cartAddressKey = this.addressType === AddressTypes.shipping ? 'shippingAddressUid' : 'billingAddressUid'
      const chosenAddressUid = this.cart[cartAddressKey]

      if (chosenAddressUid) {
        this.form.addressUid = chosenAddressUid
        return
      }

      const defaultUid = this.addresses[0].uid
      this.form.addressUid = defaultUid
      this.propagateAddressChange(defaultUid)
    })
  },
}
</script>

<style scoped>
.red {
  color: red;
}

.chosen-address-descriptor {
  margin-bottom: 8px;
}
</style>
