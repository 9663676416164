import Brands from './Brands'

const PrefixToBrand = {
  AGP: Brands.agripp,
  ATC: Brands.arctic,
  BOA: Brands.boaz,
  KKG: Brands.kong,
  KUW: Brands.incurvo,
}

export default PrefixToBrand
