import Vue from 'vue'
import VueX from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMigrate from 'vuex-persistedstate-migrate'
import createMultiTabState from 'vuex-multi-tab-state'

import app from './app'
import cart from './cart'
import dependencies from './dependencies'
import image from './image'
import language from './language'
import orders from './orders'
import products from './products'

import migrations from '../migrations/migrations'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(VueX)

const persistedState = createPersistedState({
  getState: createMigrate(migrations, 'app.version'),

  paths: [
    'app', //
    'cart',
    'language',
    'orders',
    'products',
  ],
})

export default new VueX.Store({
  modules: {
    app,
    cart,
    dependencies,
    image,
    language,
    orders,
    products,
  },
  strict: debug,
  plugins: [
    persistedState,
    createMultiTabState({
      statesPaths: ['cart'],
      // Has to be updated after a migration
      key: '14',
    }),
  ],
})
