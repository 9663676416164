<template>
  <div class="page-wrapper">
    <div class="page-header">
      <div class="page-title">
        {{ $t('My_cart') }}
      </div>
    </div>

    <div v-if="Object.keys(cartItems).length" class="page-body">
      <cart-items />
      <cart-totals />

      <div class="cart-buttons">
        <div v-if="user && user.country" class="call-to-action" @click="$router.push({ path: '/order' })">
          {{ $t('Confirm_and_place_order') }}
        </div>
        <div v-if="!user.country" class="call-to-action" @click="$router.push({ path: '/register' })">
          {{ $t('create_an_account_and_place_order') }}
        </div>
        <div class="secondary-button" @click="$router.go(-1)">{{ $t('Go_back') }}</div>
      </div>
    </div>

    <div v-else class="page-body">
      <div class="empty-cart-outer">
        <div class="empty-cart-inner">
          <img :src="emptyCart" alt="Empty cart" />
          <p>{{ $t('Your_cart_is_empty') }}...</p>
        </div>
        <div class="primary-button" @click="$router.push({ path: '/products' })">{{ $t('SHOP_NOW') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CartItems from '../components/CartItems'
import CartTotals from '../components/CartTotals'
import emptyCart from '../../public/images/app/empty-cart.svg'

export default {
  components: {
    CartItems, //
    CartTotals,
  },

  data() {
    return { emptyCart }
  },

  computed: {
    ...mapState({
      cartItems: state => state.cart.cartItems,
      user: state => state.app.user,
    }),
  },
}
</script>
<style lang="scss">
.empty-cart-outer {
  display: grid;
  place-items: center;
  height: 100%;
}

.empty-cart-outer p {
  font-weight: 700;
}

.empty-cart-inner {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  min-height: 100px;
  align-items: center;
  place-items: center;
  margin-bottom: 0px;
}

.cart-buttons {
  margin: 32px 0;
  text-align: center;

  .secondary-button {
    margin-top: 24px;
  }
}
</style>
