<template>
  <div :class="['product-wrapper', cardClass]">
    <div class="select-index-block" v-show="theOrderedCount">
      <div>{{ theOrderedCount }}</div>
      <div>{{ theOrderedCount > 1 ? ` ${$t('Sets')}` : ` ${$t('Set')}` }}</div>
    </div>

    <div class="color-block-head">{{ product.name }}</div>

    <a :href="productLinkUrl">
      <product-image :fileName="picture" :clickable="false" :materials="product.materials" />
    </a>

    <div class="brand-name">{{ $t(product.brand) }}</div>
    <div class="size-content">{{ $t('Size') }} {{ product.size }} {{ grips.length ? `- ${grips}` : '' }}</div>

    <div class="price-content">
      <product-prices v-if="product.prices.length" :product="product" />
    </div>

    <a
      class="click-to-switch"
      :href="product.prices.length ? productLinkUrl : '#'"
      :style="!product.prices.length && 'cursor: default'"
    >
      {{ clickToSwitchText }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { gripsFromShapeTypes } from '../services/formatting'
import ProductImage from './ProductImage.vue'
import ProductPrices from './ProductPrices.vue'

export default {
  components: { ProductImage, ProductPrices },

  props: {
    product: {},
  },

  computed: {
    ...mapGetters({
      referenceCount: 'cart/referenceCount',
    }),

    theOrderedCount() {
      return this.referenceCount(this.product.reference)
    },

    cardClass() {
      return this.theOrderedCount > 0 ? 'active-class' : ''
    },

    grips() {
      return gripsFromShapeTypes(this.product.shapeTypes)
    },

    productLinkUrl() {
      if (!this.theOrderedCount) return `/product/${this.product.reference}/edit`
      return `/product/${this.product.reference}`
    },

    clickToSwitchText() {
      if (!this.product.prices.length) return this.$t('AVAILABLE_SOON')
      return this.theOrderedCount > 0 ? this.$t('SEE_DETAILS') : this.$t('CHOOSE_OPTIONS')
    },

    picture() {
      return this.product.pictures[0]
    },
  },
}
</script>
<style lang="scss">
.click-to-switch {
  cursor: pointer;
}
.product-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 23px;
  box-shadow: 0 4px 7px rgb(0 0 0 / 16%);
  overflow: hidden;
  display: grid;

  img {
    width: 115px;
    height: 115px;
  }

  .click-to-switch {
    color: var(--primary);
    font-size: var(--small);
    font-weight: bold;
    margin: 12px 0;
  }

  .brand-name {
    position: relative;
  }

  .size-content {
    position: relative;
  }

  .price-content {
    font-size: var(--medium);
    position: relative;
    font-weight: bold;
    padding-top: 10px;
  }
}

.active-class {
  position: relative;
  box-shadow: inset 0px 0px 0px 2px var(--primary);

  .select-index-block {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: var(--medium);
    font-weight: bold;
    line-height: 17px;
    color: var(--dark);
    left: 0;
    top: 0;
    content: '';
    position: absolute;
    width: 33px;
    height: 69px;
    background: var(--primary);
    box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-radius: 20px 0 21px 0;
    z-index: 1;
  }

  .color-block-head {
    font-size: var(--medium);
    height: 34px;
    line-height: 32px;
  }
}
</style>
