<template>
  <thead class="page-header">
    {{
      $t('discount_policy')
    }}
    <p class="explanation">
      Discount applies automatically, based on the total amount of the order.
    </p>

    <el-table :data="displayData" class="discountTable">
      <el-table-column prop="volume" label="Volume" align="center"> </el-table-column>
      <el-table-column prop="discount" label="Discount" align="center"> </el-table-column>
    </el-table>
  </thead>
</template>

<script>
import { mapState } from 'vuex'
import { formatPriceAsString } from '../services/formatting'
export default {
  computed: {
    ...mapState({
      discountsOnVolume: state => state.products.discountsOnVolume,
    }),

    displayData() {
      return this.discountsOnVolume.map(data => {
        return {
          currency: data.currency,
          volume: formatPriceAsString(data.volume, data.currency),
          discount: `${data.discount / 100}%`,
        }
      })
    },
  },
}
</script>

<style scoped>
.page-header {
  text-align: left;
  line-height: 70px;
  width: 100%;
  font-weight: bold;
  font-size: var(--big);
}
.explanation {
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 1em;
  text-align: left;
}
.discountTable {
  font-size: var(--big);
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  margin-top: 0px;
}
</style>
