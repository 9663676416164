<template>
  <div class="page-wrapper order-detail-wrapper" id="printarea">
    <div class="page-header">
      <div class="page-title">
        {{ $t('Order_details') }}
      </div>
    </div>

    <div class="page-body pb-4">
      <div class="information-section">
        <div class="section-header">
          <span>{{ $t('General_information') }}</span>
        </div>
        <div class="section-body">
          <div class="information-table">
            <div class="information-table-body">
              <div class="information-table-row">
                <div>{{ $t('Order_date') }}</div>
                <div>{{ orderDate }}</div>
              </div>

              <div class="information-table-row">
                <div>{{ $t('Order_total') }}</div>
                <div>
                  <span v-if="showDiscount" class="amount-before-discount">{{ orderAmount }}</span>
                  <span>{{ showDiscount ? discountedOrderAmount : orderAmount }}</span>
                </div>
              </div>

              <div class="information-table-row">
                <div>{{ $t('Order_reference') }}</div>
                <div>{{ order.uid }}</div>
              </div>

              <div class="information-table-row">
                <div>{{ $t('Order_status') }}</div>
                <div>{{ $t(order.status) }}</div>
              </div>

              <div class="information-table-row">
                <div>{{ $t('Order_incoterm') }}</div>
                <div>{{ order.incoterm }}</div>
              </div>

              <div class="information-table-row">
                <div>{{ $t('Order_notes') }}</div>
                <div>{{ order.notes }}</div>
              </div>

              <div v-if="order.shipmentMode" class="information-table-row">
                <div>{{ $t('shipment_mode') }}</div>
                <div>{{ order.shipmentMode }}</div>
              </div>

              <div v-if="order.packingType" class="information-table-row">
                <div>{{ $t('packing_type') }}</div>
                <div>{{ order.packingType }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="billingAddress" class="information-section">
        <div class="section-header">
          <span>{{ $t('Billing_information') }}</span>
        </div>
        <div class="section-body">
          <address-display :address="billingAddress" :disableEdit="true" />
        </div>
      </div>

      <div v-if="shippingAddress" class="information-section">
        <div class="section-header">
          <span>{{ $t('Shipping_information') }}</span>
        </div>
        <div class="section-body">
          <address-display :address="shippingAddress" :disableEdit="true" />
        </div>
      </div>

      <div v-if="items" class="information-section">
        <div class="section-header">
          <span>{{ $t('Products') }}</span>
        </div>
      </div>
      <order-table :items="items" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import AddressTypes from '../enums/AddressTypes'

import AddressDisplay from '../components/AddressDisplay.vue'
import OrderTable from '../components/OrderTable.vue'

// @LOADING

export default {
  components: {
    AddressDisplay, //
    OrderTable,
  },

  computed: {
    ...mapGetters({
      orderByUid: 'orders/getOrderByUid',
      getOrderAddresses: 'orders/getOrderAddresses',
      getOrderItems: 'orders/getOrderItems',
    }),

    order() {
      return this.orderByUid(this.$route.params.ref)
    },

    addresses() {
      return this.getOrderAddresses(this.$route.params.ref)
    },

    shippingAddress() {
      if (!this.addresses.length) return null
      return this.addresses.find(({ addressType }) => addressType === AddressTypes.shipping)
    },

    billingAddress() {
      if (!this.addresses.length) return null
      return this.addresses.find(({ addressType }) => addressType === AddressTypes.billing)
    },

    items() {
      return this.getOrderItems(this.$route.params.ref)
    },

    orderDate() {
      return moment(this.order.createdAt).format('L')
    },

    orderAmount() {
      const amount = Number(Number(this.order.totalInBaseCurrency) / 100).toLocaleString()
      return `${this.order.currency} ${amount}`
    },

    discountedOrderAmount() {
      const amount = Number(Number(this.order.discountedTotalInBaseCurrency) / 100).toLocaleString()
      return `${this.order.currency} ${amount}`
    },

    showDiscount() {
      return (
        this.order.discountedTotalInBaseCurrency &&
        this.order.discountedTotalInBaseCurrency !== this.order.totalInBaseCurrency
      )
    },
  },

  created() {
    this.$store.dispatch('orders/setOrderData', this.$route.params.ref)
  },
}
</script>

<style lang="scss">
.order-detail-wrapper {
  display: block !important;
  .information-section {
    .section-body {
      padding: 0 6px;
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: 600px) {
    .information-section {
      margin: unset;
    }
  }

  .amount-before-discount {
    color: var(--light);
    text-decoration: line-through;
    font-size: var(--small);
    margin-right: 6px;
  }

  .information-table-row {
    display: flex;
    justify-content: space-around;
    margin-top: 6px;
    div:nth-child(1) {
      width: 100px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    div:nth-child(2) {
      width: 100%;
      text-align: right;
    }
  }
}

.pb-4 {
  padding-bottom: 40px;
}
</style>
