<template>
  <div id="app" class="main-wrap" :style="this.$route.name === 'Gallery' && 'height: 100%'">
    <TopMenu />
    <router-view :key="$route.fullPath" />
    <ProductImagePreviewer />
    <transition name="el-fade-in">
      <div class="scroll-to-top-wrapper">
        <img
          @click="scrollToTop"
          v-show="showScrollToTop && $route.path.startsWith('/product')"
          src="../public/images/svg/icon-scroll-top.svg"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import TopMenu from './components/TopMenu.vue'
import ProductImagePreviewer from './components/ProductImagePreviewer.vue'
import { mapState } from 'vuex'

export default {
  components: { TopMenu, ProductImagePreviewer },

  computed: mapState({
    showScrollToTop: state => state.app.showScrollToTop,
  }),

  methods: {
    scrollToTop() {
      document.getElementById('product-list-wrapper').scrollTop = 0
    },
  },

  async created() {
    // @TODO: Hacky
    if (this.$route.meta.open) return

    try {
      await this.$store.dispatch('app/initialize')
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
@import '../public/css/variables.css';
@import '../public/css/common.css';

a {
  text-decoration: none;
}

.main-wrap {
  max-width: var(--medium-breakpoint);
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.scroll-to-top-wrapper {
  position: absolute;
  bottom: 20px;
  right: 50px;
}

@media only screen and (max-width: 600px) {
  .scroll-to-top-wrapper {
    right: 20px;
  }
}

.scroll-to-top-wrapper img {
  cursor: pointer;
  width: 64px;
  height: 64px;
  z-index: 99;
}

.el-form-item__label {
  padding: 0 !important;
}

.el-button {
  font-weight: 300 !important;
}

.el-dialog__body {
  word-break: break-word;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
