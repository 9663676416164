<template>
  <div class="form-wrapper">
    <el-form :model="userInfoForm" :rules="rules" ref="userInfoForm" label-position="top">
      <el-form-item label="Country" label-width="120px" prop="country">
        <el-select
          v-model="userInfoForm.country"
          filterable
          :no-match-text="$t('No_matching_result')"
          :placeholder="$t('Choose_a_country')"
          autocomplete="disabled"
          style="width: 100%"
        >
          <el-option v-for="code in CountryCodes" :key="`c${code}`" :label="$t(code)" :value="code" />
        </el-select>
      </el-form-item>

      <el-form-item label="First name" label-width="120px" prop="firstName">
        <el-input v-model="userInfoForm.firstName" />
      </el-form-item>

      <el-form-item label="Last name" label-width="120px" prop="lastName">
        <el-input v-model="userInfoForm.lastName" />
      </el-form-item>

      <el-form-item label="Email" label-width="120px" prop="email">
        <el-input disabled v-model="userInfoForm.email" />
      </el-form-item>

      <el-form-item v-show="false" label="Currency" label-width="120px" prop="currency">
        <el-select v-model="userInfoForm.currency" disabled style="width: 100%">
          <el-option v-for="currency in currencies" :key="`c${currency}`" :label="currency" :value="currency" />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('on-cancel')">{{ $t('Cancel') }}</el-button>
      <el-button type="primary" @click="onFormValidation">{{ $t('Confirm') }}</el-button>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { emailPattern } from '../services/patterns'
import Currencies from '../enums/Currencies'
import CountryCodes from '../enums/CountryCodes'
import { EuCountryCodes } from '../enums/GeographicalAreas'

export default {
  data() {
    return {
      CountryCodes,

      userInfoForm: {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        uid: '',
        currency: Currencies.USD,
      },

      rules: {
        firstName: [
          { min: 1, max: 100, message: 'The first name should be 1 to 100 characters long', trigger: 'blur' },
        ],
        lastName: [{ min: 1, max: 100, message: 'The last name should be 1 to 100 characters long', trigger: 'blur' }],
        email: [{ required: true, message: 'Please input a correct email', pattern: emailPattern, trigger: 'blur' }],
        currency: [{ required: true, trigger: 'blur' }],
        country: [
          { required: true, message: 'Please input your country', trigger: 'blur' },
          { min: 2, max: 100, message: 'The country should be 2 to 100 characters long', trigger: 'blur' },
        ],
      },
    }
  },

  computed: {
    ...mapState({
      user: state => state.app.user,
    }),

    chosenCountry() {
      return this.userInfoForm.country
    },

    currencies() {
      const { country } = this.userInfoForm
      return [this.getCurrencyFromCountry(country)]
    },
  },

  watch: {
    chosenCountry(newCountry, oldCountry) {
      if (oldCountry !== newCountry) {
        this.userInfoForm.currency = this.getCurrencyFromCountry(newCountry)
      }
    },
  },

  methods: {
    onFormValidation() {
      this.$refs['userInfoForm'].validate(async valid => {
        try {
          if (valid) {
            await this.updateUserInfo()
          } else {
            throw new Error('Something went wrong...')
          }
        } catch (error) {
          console.error(error)
        }
      })
    },

    async updateUserInfo() {
      await this.$store.dispatch('app/updateUserInfo', this.userInfoForm)
      this.$router.push({ path: '/products' })
    },

    getCurrencyFromCountry(country) {
      if (!country) return ''
      if (country === 'chn') return Currencies.CNY
      if (EuCountryCodes.includes(country)) return Currencies.EUR
      return Currencies.USD
    },
  },

  async created() {
    if (this.user) {
      this.userInfoForm.firstName = this.user.firstName
      this.userInfoForm.lastName = this.user.lastName
      this.userInfoForm.email = this.user.email
      this.userInfoForm.country = this.user.country
      this.userInfoForm.uid = this.user.uid
      this.userInfoForm.currency = this.user.currency || Currencies.USD
    }
  },
}
</script>
<style lang="scss">
.form-wrapper {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
}
</style>
