<template>
  <div class="product-prices-container">
    <div :class="isDiscounted ? 'prices' : ''">
      <span :class="isDiscounted ? 'struck' : 'discounted-prices'">{{ prices }}</span>
      <span class="discount-percentage" v-if="isDiscounted">&nbsp; (-{{ discount }})</span>
    </div>
    <div class="discounted-prices" v-if="isDiscounted">{{ discountedPrices }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formattedDiscount } from '../services/formatting'
import ProductsService from '../services/ProductsService'

export default {
  props: ['product', 'quantity', 'selectedMaterial'],

  computed: {
    ...mapState({
      currency: state => state.products.pricelist.currency,
    }),

    ...mapGetters({
      getProductDiscount: 'products/getProductDiscount',
    }),

    isDiscounted() {
      return this.productDiscount
    },

    prices() {
      return ProductsService.getDisplayPriceWithMaterials(
        this.product.prices,
        this.currency,
        this.quantity,
        this.selectedMaterial,
      )
    },

    discount() {
      return formattedDiscount(this.productDiscount)
    },

    discountedPrices() {
      return ProductsService.getDiscountedDisplayPrice(
        this.product.prices,
        this.currency,
        this.productDiscount,
        this.quantity,
        this.selectedMaterial,
      )
    },

    productDiscount() {
      return this.getProductDiscount(this.product.reference)
    },
  },
}
</script>

<style lang="scss" scoped>
.prices {
  color: var(--light);
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: normal;
}
</style>
