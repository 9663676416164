<template>
  <div class="product-filters-wrapper">
    <div class="selectors-wrapper">
      <el-select
        v-model="sorting"
        :placeholder="$t('Sort_by')"
        clearable
        class="selector-select"
        autocomplete="disabled"
      >
        <el-option
          v-for="field in sortingFields"
          :key="`c${field}`"
          :label="`${$t('Sort_by_field', { field: sortBy.field })} ${sortBy.direction === 'ASC' ? upArrow : downArrow}`"
          :value="field"
        >
          {{ $t('Sort_by_field', { field }) }}
          {{ sortOptionSuffix }}
        </el-option>
      </el-select>

      <div class="filter-selector-wrapper">
        <div class="filter-selector-placeholder" v-if="filtering.length">{{ $t('Filters') }}</div>
        <el-select
          v-model="filtering"
          :placeholder="$t('Filters')"
          multiple
          collapse-tags
          class="filter-selector selector-select"
          ref="filter-selector"
          clearable
          autocomplete="disabled"
        >
          <el-option-group v-for="group in filters" :key="group.label" :label="group.label" class="filtering-options">
            <el-option v-for="filter in group.options" :key="`f${filter}`" :label="`${$t(filter)}`" :value="filter" />
          </el-option-group>
        </el-select>
      </div>

      <el-tag
        v-for="filter in allActiveFilters"
        :key="`fff${filter}`"
        class="filter-tag"
        closable
        @close="onCloseFilter(filter)"
      >
        <div class="filter-tag-text">{{ $t(filter) }}</div>
      </el-tag>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

import SortingDirections from '../enums/SortingDirections'
import SortingFields from '../enums/SortingFields'

const upArrow = String.fromCodePoint(8593)
const downArrow = String.fromCodePoint(8595)

const filterActions = {
  add: 'add',
  remove: 'remove',
}

export default {
  data() {
    return {
      sortingFields: Object.keys(SortingFields),
      upArrow,
      downArrow,
    }
  },

  computed: {
    ...mapState({
      sortBy: state => state.app.sortBy,
      brands: state => state.products.brands,
      grips: state => state.products.grips,
      sizes: state => state.products.sizes,
      materials: state => state.products.materials,
      textures: state => state.products.textures,
    }),

    ...mapGetters({
      allActiveFilters: 'app/allActiveFilters',
    }),

    sortOptionSuffix() {
      if (!this.sortBy.direction) return upArrow
      return this.sortBy.direction === 'DESC' ? upArrow : downArrow
    },

    filters() {
      return [
        {
          label: 'grips',
          options: this.grips,
        },
        {
          label: 'sizes',
          options: this.sizes,
        },
        {
          label: 'materials',
          options: this.materials,
        },
        {
          label: 'brands',
          options: this.brands,
        },
        {
          label: 'textures',
          options: this.textures,
        },
      ]
    },

    sorting: {
      get: function() {
        return this.sortBy.field
      },

      set: function(newField) {
        const currentField = this.sortBy.field
        const currentDirection = this.sortBy.direction
        let newDirection

        if (currentField !== newField) {
          newDirection = SortingDirections.ASC
        } else {
          newDirection = currentDirection === SortingDirections.DESC ? SortingDirections.ASC : SortingDirections.DESC
        }

        this.setSortBy({ field: newField, direction: newDirection })
        this.$store.dispatch('products/setDisplayedProducts')
      },
    },

    filtering: {
      get: function() {
        return this.allActiveFilters
      },

      set: function(values) {
        if (!values.length) {
          this.clearFilters()
          this.$store.dispatch('products/setDisplayedProducts')
          return
        }

        const { value, action } = this.getNewValueAndAction(values)

        const filter = this.getFilterType(value)

        if (action === filterActions.add) {
          this.addFilter({ filter, value })
        } else {
          this.removeFilter({ filter, value })
        }

        this.$store.dispatch('products/setDisplayedProducts')
      },
    },
  },

  methods: {
    ...mapMutations({
      setSortBy: 'app/setSortBy',
      clearSortBy: 'app/clearSortBy',
      addFilter: 'app/addFilter',
      removeFilter: 'app/removeFilter',
      clearFilters: 'app/clearFilters',
    }),

    getNewValueAndAction(values) {
      let action = values.length > this.allActiveFilters.length ? filterActions.add : filterActions.remove

      if (action === filterActions.add) {
        const value = [...values].filter(v => !this.allActiveFilters.includes(v))[0]
        return { action, value }
      }

      const value = [...this.allActiveFilters].filter(v => !values.includes(v))[0]
      return { action, value }
    },

    getFilterType(value) {
      if (this.brands.includes(value)) return 'brand'
      if (this.grips.includes(value)) return 'shape'
      if (this.sizes.includes(value)) return 'size'
      if (this.materials.includes(value)) return 'material'
      if (this.textures.includes(value)) return 'texture'
      throw new Error('Filter type not found')
    },

    onCloseFilter(value) {
      try {
        const filter = this.getFilterType(value)
        this.removeFilter({ filter, value })
      } catch (error) {
        console.error(error)
        this.clearFilters()
      } finally {
        this.$store.dispatch('products/setDisplayedProducts')
      }
    },
  },
}
</script>
<style lang="scss">
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: var(--light) !important;
}

.product-filters-wrapper {
  width: calc(100% - 2rem);
  font-size: var(--normal);
  color: var(--dark);

  input {
    font-size: var(--normal);
    color: var(--dark);
  }

  .selectors-wrapper {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 2rem 0;
    justify-content: start;
    column-gap: 8px;

    .el-input {
      ::placeholder {
        color: var(--dark);
        opacity: 1;
      }

      input {
        border-color: var(--lighter);
      }
    }

    .is-focus {
      .el-input__inner {
        border-color: var(--light) !important;
      }
    }

    .selector-select {
      width: 140px;
    }

    .el-tag--light {
      border-color: var(--transparent);
      background-color: var(--transparent);
    }

    .el-tag__close:hover {
      background-color: var(--dark);
    }

    .filter-selector-wrapper {
      position: relative;

      .filter-selector-placeholder {
        position: absolute;
        z-index: 1;
        padding: 0 15px;
        color: var(--dark);
        line-height: 40px;
      }

      .filter-selector {
        .el-select__tags {
          display: none;
        }
      }
    }

    .filter-tag {
      height: 40px;
      display: grid;
      grid-auto-flow: column;
      place-items: center;
      font-size: var(--normal);

      .filter-tag-text {
        color: var(--light);
        display: grid;
        place-items: center;
        height: 100%;
      }

      .el-icon-close {
        line-height: 24px !important;
        height: 20px;
        width: 20px;
      }
    }

    .filter-tag:last-child {
      margin-right: 15px;
    }
  }

  @media only screen and (max-width: 600px) {
    .selectors-wrapper {
      margin: 16px 0;
      display: grid;
    }
  }
}

@media only screen and (max-width: 600px) {
  .product-filters-wrapper {
    width: calc(100% - 1rem);
  }
}

.el-select-group__title {
  font-size: var(--medium) !important;
  text-transform: capitalize;
  font-weight: bold;
}
</style>
