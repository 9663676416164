<template>
  <div class="product-prices-container">
    <div v-if="discountedPriceOnly">
      <div v-if="this.cartItem.hasDiscount">{{ this.cartItem.prettyDiscountedTotalPrice }}</div>
      <div v-else>{{ this.cartItem.prettyTotalPrice }}</div>
    </div>

    <div v-else>
      <div :class="this.cartItem.hasDiscount ? 'prices' : ''">{{ this.cartItem.prettyTotalPrice }}</div>
      <div class="discounted-prices" v-if="this.cartItem.hasDiscount">
        {{ this.cartItem.prettyDiscountedTotalPrice }} (-{{ this.cartItem.prettyDiscount }})
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from '../models/CartItem'

export default {
  props: ['cartItemDto', 'discounted-price-only'],
  computed: {
    cartItem() {
      return CartItem.create(this.cartItemDto)
    },
  },
}
</script>

<style scoped>
.prices {
  color: var(--light);
  font-size: 12px;
  text-decoration: line-through;
  margin-bottom: 2px;
  font-weight: normal;
}
</style>
