<template>
  <div class="information-section">
    <div class="section-header">
      <span>{{ $t('Additional_information') }}</span>
    </div>

    <div class="section-body">
      <el-form :model="form" label-position="top">
        <el-form-item label="Additional notes" label-width="120px">
          <el-input type="textarea" v-model="form.notes" @change="onNotesChanged" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        notes: '',
      },
    }
  },

  methods: {
    onNotesChanged(notes) {
      this.$store.commit('cart/setNotes', notes)
    },
  },

  created() {
    this.form.notes = this.$store.state.cart.notes
  },
}
</script>
