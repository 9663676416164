<template>
  <div class="single-pic">
    <div v-if="loading">Loading...</div>
    <img v-else-if="image" :src="image" />
    <div v-else>No image found</div>
  </div>
</template>

<script>
import BackEndRepository from '../repositories/BackEndRepository'

export default {
  data: () => ({
    loading: true,
    image: null,
  }),

  async created() {
    try {
      const productReference = this.$route.params.reference
      const picNumber = Number(this.$route.params.picNumber)

      if (!productReference) throw new Error('No product reference')
      if (!picNumber) throw new Error('No pic number')

      const backEndRepository = BackEndRepository.init()

      this.product = await backEndRepository.getProduct(productReference)
      const pictures = this.product.pictures.split(';')
      const picture = pictures[picNumber - 1]
      if (!picture) throw new Error('Picture not found')
      this.image = backEndRepository.getPictureUrl(picture)
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },
}
</script>

<style scoped>
img {
  width: 100%;
  max-width: 60vh;
  margin: auto;
}

.single-pic {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
