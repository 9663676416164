const Brands = {
  360: '360',
  agripp: 'agripp',
  arctic: 'arctic',
  artline: 'artline',
  boaz: 'boaz',
  cai: 'cai',
  cheeta: 'cheeta',
  comfy: 'comfy',
  decoy: 'decoy',
  expression: 'expression',
  incurvo: 'incurvo',
  kong: 'kong',
  motif: 'motif',
  neo: 'neo',
  olio: 'olio',
  proset: 'proset',
  pusher: 'pusher',
  rockcity: 'rockcity',
  snap: 'snap',
  stax: 'stax',
  stoked: 'stoked',
  supper: 'supper',
  trango: 'trango',
  underblue: 'underblue',
  uprising: 'uprising',
}

export default Brands
