import { formatPriceAsString, formattedDiscount } from '../services/formatting'
import {
  getColorReferenceFromReference,
  getBrandFromReference,
  getMaterialFromReference,
} from '../services/ReferencesService'
import { getDiscountInPercentageFromPrices, getTotalPriceInCents } from '../services/MathService'

class CartItem {
  static create({
    reference, //
    parentReference,
    shapeReference,
    quantity,
    unitPrice,
    totalPrice,
    currency,
    discount,
    discountedTotalPrice,
  }) {
    return new CartItem(
      reference, //
      parentReference,
      shapeReference,
      quantity,
      unitPrice,
      totalPrice,
      currency,
      discount,
      discountedTotalPrice,
    )
  }

  constructor(
    reference, //
    parentReference,
    shapeReference,
    quantity,
    unitPrice,
    totalPrice,
    currency,
    discount,
    discountedTotalPrice,
  ) {
    this.reference = reference
    this.parentReference = parentReference
    this.shapeReference = shapeReference
    this.quantity = quantity
    this.unitPrice = unitPrice
    this.totalPrice = totalPrice
    this.currency = currency
    this.discount = discount
    this.discountedTotalPrice = discountedTotalPrice
  }

  recalculateTotalPrice() {
    this.totalPrice = getTotalPriceInCents(this.quantity, this.unitPrice)
  }

  get color() {
    return getColorReferenceFromReference(this.reference)
  }

  get brand() {
    return getBrandFromReference(this.reference)
  }

  get hasDiscount() {
    return this.discount > 0
  }

  get prettyUnitPrice() {
    return formatPriceAsString(this.unitPrice, this.currency)
  }

  get prettyTotalPrice() {
    return formatPriceAsString(this.totalPrice, this.currency)
  }

  get prettyDiscount() {
    const discountInPercentage = getDiscountInPercentageFromPrices(this.totalPrice, this.discountedTotalPrice)
    return formattedDiscount(discountInPercentage)
  }

  get prettyDiscountedTotalPrice() {
    return formatPriceAsString(this.discountedTotalPrice, this.currency)
  }

  get material() {
    return getMaterialFromReference(this.reference)
  }
}

export default CartItem
