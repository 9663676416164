<template>
  <div class="address-form-wrapper">
    <el-form :model="addressForm" :rules="rules" ref="addressForm" label-position="top">
      <el-form-item :label="$t('address_name')" label-width="120px" prop="name">
        <el-input v-model="addressForm.name" />
      </el-form-item>

      <el-form-item :label="$t('company_name')" label-width="120px">
        <el-input v-model="addressForm.companyName" />
      </el-form-item>

      <el-form-item :label="$t('first_name')" label-width="120px">
        <el-input v-model="addressForm.firstName" />
      </el-form-item>

      <el-form-item :label="$t('last_name')" label-width="120px">
        <el-input v-model="addressForm.lastName" />
      </el-form-item>

      <el-form-item :label="$t('phone_1')" label-width="120px" class="prepended-input">
        <el-input v-model="addressForm.phone1" placeholder="Phone number">
          <el-select
            v-model="addressForm.phone1CountryCode"
            slot="prepend"
            filterable
            placeholder="Country code"
            autocomplete="disabled"
          >
            <el-option
              v-for="({ code, name }, i) in CountryDialCodes"
              :key="`cd1${code}${i}`"
              :label="`(${code}) ${name}`"
              :value="code"
            />
          </el-select>
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('phone_2')" label-width="120px" class="prepended-input">
        <el-input v-model="addressForm.phone2" placeholder="Phone number">
          <el-select
            v-model="addressForm.phone2CountryCode"
            slot="prepend"
            filterable
            placeholder="Country code"
            autocomplete="disabled"
          >
            <el-option
              v-for="({ code, name }, i) in CountryDialCodes"
              :key="`cd2${code}${i}`"
              :label="`(${code}) ${name}`"
              :value="code"
            />
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('street_1')" label-width="120px" prop="street1">
        <el-input v-model="addressForm.street1" />
      </el-form-item>

      <el-form-item :label="$t('street_2')" label-width="120px">
        <el-input v-model="addressForm.street2" />
      </el-form-item>

      <el-form-item :label="$t('post_code')" label-width="120px" prop="postCode">
        <el-input v-model="addressForm.postCode" />
      </el-form-item>

      <el-form-item :label="$t('city')" label-width="120px" prop="city">
        <el-input v-model="addressForm.city" />
      </el-form-item>

      <el-form-item :label="$t('state')" label-width="120px">
        <el-input v-model="addressForm.state" />
      </el-form-item>

      <el-form-item :label="$t('country')" label-width="120px" prop="country">
        <el-select
          v-model="addressForm.country"
          filterable
          :no-match-text="$t('No_matching_result')"
          :placeholder="$t('Choose_a_country')"
          autocomplete="disabled"
          style="width: 100%"
        >
          <el-option v-for="code in CountryCodes" :key="`c${code}`" :label="$t(code)" :value="code" />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('notes')" label-width="120px">
        <el-input type="textarea" v-model="addressForm.notes" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{ $t('Cancel') }}</el-button>
      <el-button type="primary" @click="onFormValidation">{{ $t('Confirm') }}</el-button>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CountryCodes from '../enums/CountryCodes'
import CountryDialCodes from '../enums/CountryDialColdes'

export default {
  props: ['uid'],

  data() {
    return {
      CountryCodes,
      CountryDialCodes,

      dialogFormVisible: false,

      addressForm: {
        name: '',
        companyName: '',
        firstName: '',
        lastName: '',
        phone1: '',
        phone1CountryCode: '',
        phone2: '',
        phone2CountryCode: '',
        street1: '',
        street2: '',
        postCode: '',
        city: '',
        state: '',
        country: '',
        notes: '',
        uid: '',
      },

      rules: {
        name: [
          { required: true, message: 'Please give this address a name', trigger: 'blur' },
          { min: 2, max: 100, message: 'The name should be 2 to 100 characters long', trigger: 'blur' },
        ],
        street1: [
          { required: true, message: 'Please input the street information', trigger: 'change' },
          { min: 2, max: 100, message: 'The street information should be 2 to 100 characters long', trigger: 'blur' },
        ],
        postCode: [
          { required: true, message: 'Please input a postcode', trigger: 'change' },
          { min: 2, max: 100, message: 'The postcode should be 2 to 100 characters long', trigger: 'blur' },
        ],
        city: [
          { required: true, message: 'Please input a city', trigger: 'change' },
          { min: 2, max: 100, message: 'The city should be 2 to 100 characters long', trigger: 'blur' },
        ],
        country: [
          { required: true, message: 'Please input a country', trigger: 'change' },
          { min: 2, max: 100, message: 'The country should be 2 to 100 characters long', trigger: 'blur' },
        ],
      },
    }
  },

  computed: {
    ...mapGetters({
      addresses: 'app/userAddresses',
    }),

    existingAddress() {
      if (!this.uid) return null

      return this.addresses.find(({ uid }) => uid === this.uid)
    },
  },

  methods: {
    onFormValidation() {
      this.$refs['addressForm'].validate(async valid => {
        try {
          if (valid) {
            await this.postAddress()
          } else {
            throw new Error('Something went wrong...')
          }
        } catch (error) {
          console.error(error)
        }
      })
    },

    async postAddress() {
      await this.$store.dispatch('app/postAddress', this.addressForm)
      this.$router.go(0)
    },
  },

  async created() {
    if (this.existingAddress) {
      this.addressForm.name = this.existingAddress.name
      this.addressForm.companyName = this.existingAddress.companyName
      this.addressForm.firstName = this.existingAddress.firstName
      this.addressForm.lastName = this.existingAddress.lastName
      this.addressForm.phone1 = this.existingAddress.phone1
      this.addressForm.phone1CountryCode = this.existingAddress.phone1CountryCode
      this.addressForm.phone2 = this.existingAddress.phone2
      this.addressForm.phone2CountryCode = this.existingAddress.phone2CountryCode
      this.addressForm.street1 = this.existingAddress.street1
      this.addressForm.street2 = this.existingAddress.street2
      this.addressForm.postCode = this.existingAddress.postCode
      this.addressForm.city = this.existingAddress.city
      this.addressForm.state = this.existingAddress.state
      this.addressForm.country = this.existingAddress.country
      this.addressForm.notes = this.existingAddress.notes
      this.addressForm.uid = this.existingAddress.uid
    }
  },
}
</script>
<style lang="scss">
.address-form-wrapper {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  max-width: 400px;
  margin: 0 auto;
}

.prepended-input {
  .el-input-group__prepend {
    background-color: #fff;
  }

  .el-select {
    .el-input {
      width: 110px;
    }
  }
}
</style>
