<template>
  <div class="information-section">
    <div class="section-header">
      <span>{{ $t('My_information') }}</span>
      <div class="section-header-actions">
        <div class="secondary-button" @click="dialogFormVisible = true">
          {{ $t('Update_my_information') }}
        </div>
      </div>
    </div>

    <div class="section-body">
      <div class="section - detail">{{ $t('First_name') }}: {{ user.firstName || 'N/A' }}</div>
      <div class="section - detail">{{ $t('Last_name') }}: {{ user.lastName || 'N/A' }}</div>
      <div class="section-detail">{{ $t('Email') }}: {{ user.email || 'N/A' }}</div>
      <div :class="`section-detail ${user.country ? '' : 'red'}`">
        {{ $t('Country') }}: {{ $t(user.country) || 'N/A' }}
      </div>

      <div class="section-detail" v-if="user.exclusiveDistributorOf && user.exclusiveDistributorOf.length">
        {{ $t('exclusive_distributor_of') }}: {{ user.exclusiveDistributorOf.map(brand => $t(brand)).join(', ') }}
      </div>
    </div>

    <el-dialog :title="$t('Update_my_information')" :visible.sync="dialogFormVisible" width="94%">
      <user-information-form @on-cancel="dialogFormVisible = false" />
    </el-dialog>

    <div v-if="!userCountry" @click="dialogFormVisible = true" class="primary-button">
      {{ $t('Update_my_information') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import UserInformationForm from './UserInformationForm.vue'

export default {
  props: ['user'],

  components: { UserInformationForm },

  data() {
    return {
      dialogFormVisible: false,
    }
  },
  computed: {
    ...mapState({
      userCountry: state => state.app.userCountry,
    }),
  },
}
</script>

<style scoped>
.red {
  color: red;
  font-weight: bold;
}
.section-detail {
  color: black;
}
</style>
