<template>
  <div id="brand-selectors-wrapper" class="no-select">
    <div v-for="brand in brands" :key="'bb' + brand" class="brand-selector" @click="onBrandClicked(brand)">
      <img v-if="getBrandImage(brand)" :src="getBrandImage(brand)" :alt="brand" />
      <div v-else class="brand-section-text-placeholder">{{ brand }}</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import GetBrandImage from '../services/GetBrandImage'

export default {
  computed: {
    ...mapState({
      brands: state => state.products.brands,
    }),
  },

  methods: {
    ...mapMutations({
      addFilter: 'app/addFilter',
    }),

    getBrandImage(brand) {
      return GetBrandImage(brand)
    },

    onBrandClicked(brand) {
      this.addFilter({ filter: 'brand', value: brand })
      this.$store.dispatch('products/setDisplayedProducts')
    },
  },
}
</script>

<style lang="scss">
#brand-selectors-wrapper {
  width: 100%;
  width: calc(100% - 2rem);
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding: 0 1rem 2rem 1rem;

  .brand-selector {
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid var(--lighter);
    display: grid;
    place-content: center;
    aspect-ratio: 1 / 1;

    img {
      margin: 0 auto;
      width: 90%;
    }

    .brand-section-text-placeholder {
      display: grid;
      place-content: center;
      margin-top: 0 !important;
      font-size: 28px;
    }
  }
  .brand-selector:hover {
    border: 1px solid var(--primary);
    box-shadow: inset 0 0 0 1px var(--primary);
  }
}

@media only screen and (max-width: 600px) {
  #brand-selectors-wrapper {
    width: calc(100% - 1rem);
  }
}
</style>
