<template>
  <div class="order-table-wrapper">
    <el-table :data="dataView" style="width: 100%">
      <el-table-column width="120" label="Reference" prop="reference" />
      <el-table-column width="100" label="Color" prop="color" />
      <el-table-column label="Material" prop="material" />
      <el-table-column label="Name" prop="name" />
      <el-table-column label="Brand" prop="brand" />
      <el-table-column label="Quantity" prop="quantity" />
      <el-table-column label="Unit price" prop="unitPrice" />
      <el-table-column width="100" label="Total price" prop="totalPrice" />
      <el-table-column width="100" label="Discount" prop="discount" />
      <el-table-column width="100" label="Total price after discount" prop="discountedTotalPrice" />
    </el-table>
  </div>
</template>

<script>
import OrderItemsView from '../assemblers/OrderItemsView'

export default {
  props: ['items'],

  computed: {
    dataView() {
      const { products, colors } = this.$store.state.products
      const view = OrderItemsView.create(products, colors)
      return view.getFromOrderItems(this.items)
    },
  },
}
</script>

<style lang="scss">
.order-table-wrapper {
  min-width: 100%;
  width: 100%;
  overflow-y: auto;
}
</style>
