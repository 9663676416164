import Vue from 'vue'
import ProductsService from '../services/ProductsService'

import { capitalize } from '../services/StringHelpers'
import { getMaterialNameFromMaterialCode } from '../services/Materials'
import Textures from '../enums/Textures'

const defaultState = {
  products: [],
  pricelist: {},
  discountsOnVolume: [],
  displayedProducts: [],
  brands: [],
  grips: [],
  sizes: [],
  materials: [],
  colors: [],
  textures: [Textures.full_texture, Textures.dual_texture],
  loading: true,
}

const state = {
  products: defaultState.products,
  pricelist: defaultState.pricelist,
  discountsOnVolume: defaultState.discountsOnVolume,
  displayedProducts: defaultState.displayedProducts,
  brands: defaultState.brands,
  grips: defaultState.grips,
  sizes: defaultState.sizes,
  materials: defaultState.materials,
  colors: defaultState.colors,
  textures: defaultState.textures,
  loading: defaultState.loading,
}

const getters = {
  getProductByReference: state => chosenReference => {
    return state.products.find(({ reference }) => reference === chosenReference)
  },

  getProductDiscount: (state, getters, rootState) => () => {
    return rootState.cart.discountInPercentage
  },
}

const mutations = {
  setProducts(state, products) {
    Vue.set(state, 'products', products)
  },
  setPricelist(state, pricelist) {
    Vue.set(state, 'pricelist', pricelist)
  },
  setDiscountsOnVolume(state, discountsOnVolume) {
    Vue.set(state, 'discountsOnVolume', discountsOnVolume)
  },
  setBrands(state, brands) {
    Vue.set(state, 'brands', brands)
  },
  setGrips(state, grips) {
    Vue.set(state, 'grips', grips)
  },
  setSizes(state, sizes) {
    Vue.set(state, 'sizes', sizes)
  },
  setMaterials(state, materials) {
    Vue.set(state, 'materials', materials)
  },
  setColors(state, colors) {
    Vue.set(state, 'colors', colors)
  },
  setDisplayedProducts(state, products) {
    Vue.set(state, 'displayedProducts', products)
  },
  setLoading(state, loading) {
    Vue.set(state, 'loading', loading)
  },
}

const actions = {
  async initialize({ commit, rootState, state, dispatch }) {
    commit('setLoading', true)
    const repository = rootState.dependencies.backEndRepository
    const { user, userCountry } = rootState.app
    const { products, prices, pricelist, discountsOnVolume } = await repository.getProducts(user, userCountry)

    const currentPricelist = state.pricelist
    if (currentPricelist.currency && currentPricelist.name) {
      if (currentPricelist.currency !== pricelist.currency || currentPricelist.name !== pricelist.name) {
        dispatch('cart/reset', null, { root: true })
      }
    }

    // @TODO: Cleanup currency management
    const updatedUserInfo = { ...rootState.app.user, currency: pricelist.currency }
    commit('setPricelist', pricelist)
    commit('setDiscountsOnVolume', discountsOnVolume)
    commit('app/setUserInfo', updatedUserInfo, { root: true })

    const productsWithPrices = ProductsService.addPricesToProducts(products, prices)
    const attributes = ['shapeTypes', 'brand', 'size', 'materials']
    const { shapeTypes, size, materials, brand } = ProductsService.getAttributesFromProducts(products, attributes)

    commit('setProducts', productsWithPrices)
    commit('setGrips', shapeTypes.map(capitalize))
    commit('setSizes', size)

    commit(
      'setMaterials',
      materials.map(getMaterialNameFromMaterialCode).filter((m, i, s) => s.indexOf(m) === i),
    )

    commit(
      'setBrands',
      brand.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
    )

    const colors = await repository.getColors()
    commit('setColors', colors)
    dispatch('setDisplayedProducts', productsWithPrices)
    commit('setLoading', false)
  },

  setDisplayedProducts({ commit, rootGetters, state, rootState }) {
    commit('setLoading', true)
    let products = [...state.products]

    const isSortedOrFiltered = rootGetters['app/isSortedOrFiltered']

    if (!isSortedOrFiltered) {
      commit('setDisplayedProducts', products)
      commit('setLoading', false)
      return
    }

    const { field, direction } = rootState.app.sortBy
    const filters = rootGetters['app/allActiveFilters']

    if (field) {
      ProductsService.sortProducts(products, field, direction)
    }

    if (filters.length) {
      const filtersByCategory = rootGetters['app/filtersByCategory']
      products = ProductsService.filterProducts(products, filtersByCategory)
    }

    commit('setDisplayedProducts', products)
    commit('setLoading', false)
  },

  reset({ commit }) {
    commit('setProducts', defaultState.products)
    commit('setPricelist', defaultState.pricelist)
    commit('setDiscountsOnVolume', defaultState.discountsOnVolume)
    commit('setBrands', defaultState.brands)
    commit('setGrips', defaultState.grips)
    commit('setSizes', defaultState.sizes)
    commit('setMaterials', defaultState.materials)
    commit('setColors', defaultState.colors)
    commit('setDisplayedProducts', defaultState.displayedProducts)
  },
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
}
