import Axios from 'axios'
import router from '../plugins/vueRouter'

const { VUE_APP_API_PROTOCOL, VUE_APP_API_HOST, VUE_APP_API_PORT } = process.env

class WebClient {
  static init() {
    return new WebClient()
  }

  constructor() {
    this.axios = Axios.create({ origin: 'http://localhost:8080' })
    this.baseUrl = this.getBaseUrl()
  }

  getBaseUrl() {
    if (VUE_APP_API_PROTOCOL === 'http') {
      return `${VUE_APP_API_PROTOCOL}://${VUE_APP_API_HOST}:${VUE_APP_API_PORT}`
    }
    return `${VUE_APP_API_PROTOCOL}://${VUE_APP_API_HOST}`
  }

  setBearerToken(token) {
    this.axios.defaults.headers.common = { Authorization: `bearer ${token}` }
  }

  async post(args) {
    try {
      const { route, body, params } = args
      const res = await this.axios.post(`${this.baseUrl}${route}`, body, { params })
      return res.data
    } catch (error) {
      if (args.route.indexOf('/login') > -1) {
        throw new Error(error.response?.data.message || 'Something went wrong')
      }
      return this.handleAxiosError(error)
    }
  }

  async put(args) {
    try {
      const { route, body, params } = args
      const res = await this.axios.put(`${this.baseUrl}${route}/${params.id}`, body)
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  async get(args) {
    try {
      const { route, params } = args
      const res = await this.axios.get(`${this.baseUrl}${route}`, { params })
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  async delete(args) {
    try {
      const { route, params } = args
      const res = await this.axios.delete(`${this.baseUrl}${route}/${params.id}`)
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  handleAxiosError(error) {
    const status = error.response?.status
    if (!status) {
      throw new Error(error)
    }
    if (status === 401 || status === 403) this.handleUnauthorized(status)
    throw new Error(error.response?.data.message || 'something went wrong')
  }

  handleUnauthorized(status) {
    if (!router.currentRoute.meta?.protected) return

    if (status === 401) {
      router.push({ path: '/welcome' })
      return
    }

    if (status === 403) {
      throw new Error('This user is not allowed to do this operation')
    }
  }
}

export default WebClient
