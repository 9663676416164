<template>
  <div class="information-section">
    <div class="section-header">
      <span>{{ $t('Shipping_information') }}</span>
      <div class="section-header-actions">
        <div class="secondary-button" @click="dialogFormVisible = true">{{ $t('create_a_new_address') }}</div>
      </div>
    </div>
    <div class="section-body">
      <el-form :model="form" :rules="rules" ref="addressForm" label-position="top">
        <el-form-item label="Incoterm" label-width="120px" prop="incoterm">
          <el-select v-model="form.incoterm" @change="onIncotermChanged" autocomplete="disabled">
            <el-option v-for="incoterm in Incoterms" :key="incoterm" :label="incoterm" :value="incoterm" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-question" @click="incotermsVisible = !incotermsVisible"
            >Learn more about incoterms</el-button
          >
        </el-form-item>

        <el-form-item label="Packing Type" label-width="120px" prop="incoterm">
          <el-select v-model="form.packingType" @change="onPackingTypeChanged" autocomplete="disabled">
            <el-option
              v-for="packingType in PackingTypes"
              :key="packingType"
              :label="$t(packingType)"
              :value="packingType"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Shipment Mode" label-width="120px" prop="shipmentMode">
          <el-select v-model="form.shipmentMode" @change="onShipmentModeChanged" autocomplete="disabled">
            <el-option
              v-for="shipmentMode in shipmentModes"
              :key="shipmentMode"
              :label="$t(shipmentMode)"
              :value="shipmentMode"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-question"
            @click="packingInstructionsVisible = !packingInstructionsVisible"
            >Learn more about shipment and packing</el-button
          >
        </el-form-item>
      </el-form>

      <address-selector :addressType="AddressTypes.shipping" />
    </div>

    <el-dialog :title="$t('create_a_new_address')" :visible.sync="dialogFormVisible" width="94%">
      <address-form :uid="null" />
    </el-dialog>

    <el-dialog :title="$t('Incoterms reference')" :visible.sync="incotermsVisible" class="incoterms-dialog">
      <incoterms-reference />
    </el-dialog>

    <el-dialog title="Information about packing and shipping" :visible.sync="packingInstructionsVisible" width="94%">
      <div class="packing-information">
        <h1>Individual packing</h1>

        <p>
          Each set is packed in its individual cardbox. <br />
          Perfect if sets are to be sold individually after reception.
        </p>

        <h1>Bulk packing</h1>

        <p>
          Holds are packed in polybags. Polybags are labelled with stickers indicating set name, color, etc... <br />
          Polybags belonging to one same set are taped together. <br />
          Sets are not packed in their individual cardboxes, they are packed in bigger cardboxes containing several sets
          together. <br />
          This packing type saves on shipment total volume but is not recommended if sets are to be sold individually
          after reception.
        </p>

        <h1>Shipment with dispatch</h1>
        <p>
          With the dispatch shipment mode, finished products are bulk packed (see definition), <br />
          and shipped through different containers before being collected and shipped in one single shipement at the
          final destination. <br />
          Dispatch shipment mode availability can depend on the final destination. <br />
          Dispatch shipment mode takes one more week than normal shipment mode but is 20 to 30 % cheaper.
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddressTypes from '../enums/AddressTypes'
import Incoterms from '../enums/Incoterms'
import PackingTypes from '../enums/PackingTypes'
import ShipmentModes from '../enums/ShipmentModes'

import AddressForm from './AddressForm.vue'
import AddressSelector from './AddressSelector.vue'
import IncotermsReference from './IncotermsReference.vue'

const modesIncompatibleWithIndividualPacking = [ShipmentModes.airDispatch, ShipmentModes.seaDispatch]

export default {
  components: {
    AddressForm, //
    AddressSelector,
    IncotermsReference,
  },

  data() {
    return {
      dialogFormVisible: false,
      packingInstructionsVisible: false,
      incotermsVisible: false,
      Incoterms,
      PackingTypes,
      AddressTypes,
      form: {
        incoterm: '',
        shipmentMode: '',
        packingType: '',
      },
      rules: {
        incoterm: [{ required: true, message: 'Please select an incoterm', trigger: 'change' }],
        shipmentMode: [{ required: true, message: 'Please select a shipment mode', trigger: 'change' }],
        packingType: [{ required: true, message: 'Please select a packing type', trigger: 'change' }],
      },
    }
  },

  computed: {
    shipmentModes() {
      if (this.form.packingType === PackingTypes.individual) {
        return Object.keys(ShipmentModes).filter(mode => !modesIncompatibleWithIndividualPacking.includes(mode))
      }

      return Object.keys(ShipmentModes)
    },
  },

  methods: {
    onIncotermChanged() {
      this.$store.commit('cart/setIncoterm', this.form.incoterm)
    },

    onShipmentModeChanged() {
      this.$store.commit('cart/setShipmentMode', this.form.shipmentMode)
    },

    onPackingTypeChanged() {
      if (this.form.packingType === PackingTypes.individual) {
        if (modesIncompatibleWithIndividualPacking.includes(this.form.shipmentMode)) {
          const defaultShipmentMode = ShipmentModes.seaNoDispatch
          this.form.shipmentMode = defaultShipmentMode
          this.$store.commit('cart/setShipmentMode', defaultShipmentMode)
          return
        }
      }

      this.$store.commit('cart/setPackingType', this.form.packingType)
    },
  },

  mounted() {
    this.form.incoterm = this.$store.state.cart.incoterm
    this.form.shipmentMode = this.$store.state.cart.shipmentMode
    this.form.packingType = this.$store.state.cart.packingType
  },
}
</script>

<style lang="scss">
.incoterms-dialog {
  .el-dialog {
    max-width: 94%;
    width: 94%;
  }
}

.packing-information {
  h1 {
    margin-top: 28px;
    font-size: 20px;
  }

  h1:first-of-type {
    margin-top: 0px;
  }

  h2 {
    margin: 12px 0 6px 0;
  }
}
</style>
