const KUW0610Migration = {
  version: 14,
  up: state => {
    try {
      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: state.cart.cartItems.filter(({ shapeReference }) => shapeReference !== 'KUW0610'),
        },
        app: {
          ...state.app,
          version: 14,
        },
      }
    } catch (error) {
      console.error(error)
    }
  },
}

export default KUW0610Migration
