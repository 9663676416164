<template>
  <div>
    <div class="cart-totals" v-if="isDiscounted">
      <div>{{ $t('Total_set_quantity') }}:</div>
      <div class="value">{{ totalSetQuantity }}</div>

      <div>{{ $t('Total_amount') }}:</div>
      <div class="value">{{ total }}</div>

      <div>{{ $t('Discount') }}:</div>
      <div class="value">{{ discount }}</div>

      <div>{{ $t('Total after discount') }}:</div>
      <div class="value">{{ discountedTotal }}</div>
    </div>

    <div v-else class="cart-totals">
      <div>{{ $t('Total_set_quantity') }}:</div>
      <div class="value">{{ totalSetQuantity }}</div>

      <div>{{ $t('Total_amount') }}:</div>
      <span :class="isDiscounted ? 'price' : 'single-total'">{{ total }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formattedDiscount, formatPriceAsString } from '../services/formatting'

export default {
  computed: {
    ...mapState({
      totalPrice: state => state.cart.totalPrice,
      discountedTotalPrice: state => state.cart.discountedTotalPrice,
      discountInPercentage: state => state.cart.discountInPercentage,
      currency: state => state.products.pricelist.currency,
    }),

    ...mapGetters({
      totalSetQuantity: 'cart/totalSetQuantity',
    }),

    isDiscounted() {
      return this.discountInPercentage
    },

    total() {
      return formatPriceAsString(this.totalPrice, this.currency)
    },

    discountedTotal() {
      return formatPriceAsString(this.discountedTotalPrice, this.currency)
    },

    discount() {
      return formattedDiscount(this.discountInPercentage)
    },
  },
}
</script>

<style lang="scss">
.cart-totals {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  max-width: 300px;
  font-weight: bold;
  align-items: center;
  .single-total {
    text-align: right;
  }

  .price {
    color: var(--lighter);
    text-decoration: line-through;
    margin-bottom: 2px;
    font-weight: normal;
  }

  .discounted-price {
    color: var(--accent);
    font-size: var(--big);
  }

  .value {
    text-align: right;
  }
}
</style>
