<template>
  <div class="address-list-wrapper">
    <div v-if="!addresses || !addresses.length">
      <div class="secondary-button" @click="dialogFormVisible = true">{{ $t('Create_an_address_now') }}</div>
    </div>

    <div v-else class="addresses-wrapper">
      <div v-for="(address, index) in addresses" class="address-wrapper" :key="`a${index}`">
        <address-display
          :address="address"
          @on-edit-address="onEditAddress(address.uid)"
          @on-delete-address="onDeleteAddress(address.uid)"
          :disableEdit="false"
        />
      </div>
    </div>

    <el-dialog :title="$t('create_a_new_address')" :visible.sync="dialogFormVisible" width="94%">
      <address-form :uid="selectedAddressUid" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressForm from './AddressForm.vue'
import AddressDisplay from './AddressDisplay.vue'

export default {
  components: {
    AddressForm, //
    AddressDisplay,
  },

  data() {
    return {
      dialogFormVisible: false,
      selectedAddressUid: '',
    }
  },

  computed: {
    ...mapGetters({
      addresses: 'app/userAddresses',
    }),
  },

  methods: {
    onEditAddress(uid) {
      this.selectedAddressUid = uid
      this.dialogFormVisible = true
    },

    async onDeleteAddress(uid) {
      this.$confirm(this.$t('this_will_permanently_delete_this_address_continue'), this.$t('Warning'), {
        confirmButtonText: this.$t('OK'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
      })
        .then(async () => {
          await this.deleteAddress(uid)
        })
        .catch(() => {
          /* nothing */
        })
    },

    async deleteAddress(uid) {
      try {
        await this.$store.dispatch('app/deleteAddressByUid', uid)
      } catch (error) {
        console.error(error)
      } finally {
        this.$router.go(0)
      }
    },
  },

  created() {
    this.$store.dispatch('app/setUserAddresses')
  },
}
</script>
<style lang="scss">
.address-list-wrapper {
  width: 100%;
  display: grid;
  grid-auto-flow: row;

  .addresses-wrapper {
    display: grid;
    grid-auto-flow: row;
    row-gap: 20px;
  }
}
</style>
