class PricesService {
  static create(user, discountsOnVolume) {
    return new PricesService(user, discountsOnVolume)
  }

  constructor(user, discountsOnVolume) {
    this.user = user
    this.discountsOnVolume = discountsOnVolume

    this.cartItems = []
    this.totalPrice = 0
    this.discountOnVolume = 0
    this.discountedTotalPrice = 0
    this.discountInValue = 0
    this.discountInPercentage = 0
  }

  computePrices(cartItems) {
    this.cartItems = cartItems
    this.setTotalPrice()
    this.setDiscountOnVolume()
    this.updateCartItems()
    this.setDiscountedTotalPrice()
    this.setDiscount()

    return {
      cartItems: this.cartItems,
      totalPrice: this.totalPrice,
      discountOnVolume: this.discountOnVolume,
      discountedTotalPrice: this.discountedTotalPrice,
      discountInValue: this.discountInValue,
      discountInPercentage: this.discountInPercentage,
    }
  }

  setTotalPrice() {
    this.totalPrice = this.cartItems.reduce((acc, { totalPrice }) => (acc += Number(totalPrice)), 0)
  }

  setDiscountOnVolume() {
    for (let { volume, discount } of this.discountsOnVolume) {
      if (this.totalPrice > volume) {
        this.discountOnVolume = discount
        return
      }
    }
  }

  updateCartItems() {
    this.cartItems = this.cartItems.map(item => this.updateCartItemDiscountedPrice(item))
  }

  updateCartItemDiscountedPrice(cartItem) {
    const discountMultiplier = this.getDiscountMultiplier()
    const multiplier = Number(discountMultiplier)
    cartItem.discountedTotalPrice = Number(Number(cartItem.totalPrice * multiplier).toFixed(0))
    cartItem.discount = cartItem.totalPrice - cartItem.discountedTotalPrice
    return cartItem
  }

  getDiscountMultiplier() {
    if (this.user && this.user.exclusiveDistributorOf && this.user.exclusiveDistributorOf.length) {
      return 1
    }

    if (!this.discountOnVolume) return 1
    return Number(((10_000 - this.discountOnVolume) / 10_000).toFixed(2))
  }

  setDiscountedTotalPrice() {
    this.discountedTotalPrice = this.cartItems.reduce(
      (acc, { discountedTotalPrice }) => (acc += Number(discountedTotalPrice)),
      0,
    )
  }

  setDiscount() {
    this.discountInValue = this.totalPrice - this.discountedTotalPrice

    if (this.discountInValue === 0) {
      this.discountInPercentage = 0
      return
    }

    this.discountInPercentage = Number(Number((this.discountInValue / this.totalPrice) * 1_0000).toFixed(0))
  }
}

export default PricesService
