import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

import Cart from '../views/Cart'
import DiscountPolicy from '../views/DiscountPolicy'
import ForgotPassword from '../views/ForgotPassword'
import Gallery from '../views/Gallery'
import GuestLogin from '../components/GuestLogin'
import MyInformation from '../views/MyInformation'
import OrderDetail from '../views/OrderDetail'
import Orders from '../views/Orders'
import OrderWizard from '../views/OrderWizard'
import PasswordLogin from '../views/PasswordLogin'
import ProductDetail from '../components/ProductDetail'
import ProductEdit from '../components/ProductEdit'
import ProductList from '../views/ProductList'
import Register from '../views/Register'
import SetPassword from '../views/SetPassword'
import SinglePic from '../views/SinglePic'
import ValidateEmail from '../views/ValidateEmail'
import Welcome from '../views/Welcome'

const routes = [
  { path: '/cart', component: Cart, meta: { needUserCountry: true } },
  { path: '/gallery/:reference', name: 'Gallery', component: Gallery, meta: { open: true } },
  { path: '/gallery/:reference/:picNumber', name: 'Single Picture', component: SinglePic, meta: { open: true } },
  { path: '/my-information/:showCompleteInfoPrompt?', component: MyInformation, meta: { protected: true } },
  { path: '/order', component: OrderWizard, meta: { protected: true, needUserCountry: true } },
  { path: '/orders', component: Orders, meta: { protected: true, needUserCountry: true } },
  { path: '/orders/detail/:ref', component: OrderDetail, meta: { protected: true, needUserCountry: true } },
  { path: '/product/:ref', component: ProductDetail, meta: { needUserCountry: true } },
  { path: '/product/:ref/edit', component: ProductEdit, meta: { needUserCountry: true } },
  { path: '/products', component: ProductList, meta: { needUserCountry: true } },
  { path: '/discount-policy', component: DiscountPolicy, meta: { needUserCountry: true } },

  { path: '/forgot-password', component: ForgotPassword },
  { path: '/login', component: PasswordLogin },
  { path: '/password-login', component: PasswordLogin },
  { path: '/register', component: Register },
  { path: '/set-password/:token/:email', component: SetPassword },
  { path: '/verify-email/:token/:email', component: ValidateEmail },
  { path: '/guest-login', component: GuestLogin },
  { path: '/welcome', component: Welcome },
  { path: '/', redirect: '/products', meta: { needUserCountry: true } },

  { path: '/*', component: ProductList, meta: { needUserCountry: true } },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.open) return next()

  const { user, userCountry } = store.state.app

  if (!to.meta.protected && !to.meta.needUserCountry) return next()

  if (!user?.country) {
    if (to.meta.needUserCountry && !userCountry && !user.country) {
      return router.push({ path: '/my-information/showCompleteInfoPrompt=true' })
    }

    if (to.meta.needUserCountry && !userCountry) {
      return router.push({ path: `/welcome` })
    }

    return next()
  }

  if (store.state.app?.user?.country) {
    return next()
  }

  if (!store.state.app?.user?.country) {
    const nowLoggedIn = await store.dispatch('app/setUserInfo')
    if (nowLoggedIn) return next()
  }

  router.push({ path: '/welcome' })
})

router.beforeResolve((to, from, next) => {
  if (to.path.indexOf('/products') > -1) store.commit('app/setCartOrProducts', 'products')
  if (to.path.indexOf('/cart') > -1) store.commit('app/setCartOrProducts', 'cart')

  // Hacky, do something better if adding more logic
  if (to.path.indexOf('/product/') === 0 && to.params.ref) {
    store.commit('app/setSelectedReference', to.params.ref)
  } else {
    store.commit('app/setSelectedReference', null)
  }
  next()
})

router.afterEach(to => {
  if (to.name !== 'Gallery') return
  Vue.nextTick(() => {
    document.title = `Kastline image viewer - ${to.params.reference}`
  })
})

export default router
