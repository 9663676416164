export const getTotalPriceInCents = (quantity, unitPriceInCents) => {
  const qty = Number(quantity)
  const unitPrice = Number(unitPriceInCents)
  const totalPrice = Number(Number(qty * unitPrice).toFixed(0))
  return totalPrice
}

export const getDiscountInPercentageFromPrices = (price, discountedPrice) => {
  const totalPrice = Number(price)
  const discount = totalPrice - Number(discountedPrice)
  const discountInPercentage = Number(Number(Number(discount / totalPrice) * 10_000).toFixed(0))
  return discountInPercentage
}
