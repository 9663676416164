import PrefixToBrand from '../enums/PrefixToBrand'

export const getColorReferenceFromReference = reference => {
  try {
    return reference.split('-').pop()
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getBrandFromReference = reference => {
  try {
    const prefix = reference.substr(0, 3)
    return PrefixToBrand[prefix]
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getMaterialFromReference = reference => {
  const [, material] = reference.split('-')
  return material || 'N/A'
}

export const buildFinalReference = (shapeReference, color, material) => {
  return `${shapeReference}-${color}-${material}`
}
