<template>
  <div @click="showImagePreview" :class="['image-container', imageUrl ? imageContainerClass : 'error-image']">
    <div class="material-chips">
      <el-tag class="product-material-chip" effect="plain" v-for="(material, i) in materials" :key="`pi-${i}`">
        {{ $t(getMaterialNameFromMaterialCode(material)) }}
      </el-tag>
    </div>

    <div :class="clickable ? 'magnifier' : ''" style="padding-top: 16px">
      <img v-lazy="thumbnailUrl" @load="imageContainerClass = ''" @error="imageContainerClass = 'error-image'" />
      <Loading v-if="imageContainerClass == 'loading-image' && imageUrl"></Loading>
    </div>
  </div>
</template>

<script>
import { getMaterialNameFromMaterialCode } from '../services/Materials'

import Loading from './Loading.vue'

export default {
  props: ['fileName', 'clickable', 'materials'],

  components: { Loading },

  data: () => {
    return {
      imageContainerClass: 'loading-image',
      getMaterialNameFromMaterialCode,
      image: null,
    }
  },

  methods: {
    showImagePreview() {
      if (!this.clickable) return
      this.$store.commit('image/setImagePreviewShow', this.imageUrl)
    },
  },

  computed: {
    theMaterials() {
      return this.materials.split(';').map(getMaterialNameFromMaterialCode)
    },

    thumbnailUrl() {
      const { backEndRepository } = this.$store.state.dependencies
      return backEndRepository.getThumbnailUrl(this.fileName)
    },

    imageUrl() {
      const { backEndRepository } = this.$store.state.dependencies
      return backEndRepository.getPictureUrl(this.fileName)
    },
  },
}
</script>

<style lang="scss">
.material-chips {
  position: absolute;
  top: 0;
  right: 0;

  .product-material-chip {
    margin-right: 5px;
    height: 22px;
    line-height: 22px;
  }

  .el-tag--plain {
    color: var(--light);
    border-color: var(--lighter);
  }
}

.image-container {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
}

.error-image,
.loading-image {
  img {
    display: inline-block;
    content: '';
    color: transparent;
    opacity: 0;
  }
}

.error-image {
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    margin: auto;
    width: 50%;
    height: 50%;
    background-repeat: no-repeat;
    background-image: url('../../public/images/svg/icon-error-image.svg');
    background-color: white;
    background-position: center;
  }

  .image-preview {
    position: fixed;
    z-index: 1000;
    background-color: black;
    width: 100vw;
    height: 100vh;
  }
}

.magnifier {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>
