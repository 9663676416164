<template>
  <div class="login-wrapper">
    <img :src="KastlineLogo" alt="Kastline logo" class="kastline-logo" />

    <div v-if="wasEmailSent" class="check-your-inbox-container">
      <img :src="CheckInboxIcon" alt="Check inbox icon" />
      <div class="friendly-tips">{{ $t('Check_your_inbox') }}</div>
    </div>

    <div v-else class="login-input-container mt-40">
      <div class="login-description">
        <div class="headline">
          {{ $t('create_your_account') }}
        </div>
        <div class="headline">
          {{ $t('enter_a_valid_email_and_a_password_to_create_your_account') }}
        </div>
        <div v-if="error" class="red mt-20">{{ error }}</div>
      </div>

      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-position="top"
        onsubmit="event.preventDefault()"
        @keyup.enter.native="onFormSubmitted"
      >
        <el-form-item prop="email">
          <el-input v-model="loginForm.email" :placeholder="$t('Email')" />
        </el-form-item>

        <el-form-item prop="password">
          <el-input v-model="loginForm.password" :type="passwordFieldType" :placeholder="$t('create_your_password')">
            <i
              slot="suffix"
              title="Show password"
              @click="togglePasswordFieldType"
              style="cursor: pointer"
              class="el-icon-view"
            />
          </el-input>
        </el-form-item>

        <el-form-item prop="country">
          <el-select
            v-model="loginForm.country"
            filterable
            :no-match-text="$t('No_matching_result')"
            :placeholder="$t('Choose_a_country')"
            autocomplete="disabled"
            style="width: 100%"
          >
            <el-option v-for="code in CountryCodes" :key="`c${code}`" :label="$t(code)" :value="code" />
          </el-select>
        </el-form-item>
      </el-form>

      <div class="call-to-action mt-20" @click="onFormSubmitted()">{{ $t('register') }}</div>
      <div class="secondary-button mt-30" @click="$router.push({ path: '/login' })">
        {{ $t('already_have_an_account_go_to_login') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { emailPattern } from '../services/patterns'
import CheckInboxIcon from '../../public/images/icons/check-inbox.svg'
import KastlineLogo from '../../public/images/logo/kastline_logo.png'
import CountryCodes from '../enums/CountryCodes'

export default {
  data() {
    return {
      KastlineLogo,
      CheckInboxIcon,
      CountryCodes,

      wasEmailSent: false,

      loginForm: {
        email: '',
        password: '',
        country: '',
      },

      passwordFieldType: 'password',

      rules: {
        email: [{ required: true, message: 'Please input a valid email', pattern: emailPattern, trigger: 'blur' }],
        password: [
          { required: true, message: 'Please input a password', trigger: 'blur' },
          { min: 2, max: 100, message: 'The password should be 2 to 100 characters long', trigger: 'blur' },
        ],
        country: [{ required: true, message: 'Please chose your country' }],
      },

      error: '',
    }
  },

  computed: {
    ...mapState({
      userCountry: state => state.app.userCountry,
    }),
  },

  methods: {
    async onFormSubmitted() {
      this.$refs['loginForm'].validate(async valid => {
        try {
          if (valid) {
            const { email, password, country } = this.loginForm

            await this.$store.dispatch('app/register', { email, password, country })
            this.wasEmailSent = true
          } else {
            throw new Error('Something went wrong...')
          }
        } catch (error) {
          if (error.message.includes('User already exists')) {
            this.error = 'A registration has already been done with this email. Please try to login instead.'
          }
          console.error(error)
        }
      })
    },

    togglePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },

  mounted() {
    this.loginForm.country = this.userCountry
  },
}
</script>
<style lang="scss">
.login-wrapper,
.login-input-container,
.login-failed-container,
.check-your-inbox-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-wrapper {
  .kastline-logo {
    margin-top: 50px;
    max-width: 290px;
  }

  .svg-magic {
    margin-top: 70px;
  }

  .lines-container {
    margin-top: 30px;

    div {
      width: 206px;
      height: 17px;
      background: var(--lighter);
      margin-bottom: 6px;
    }
  }

  input {
    margin-top: 0px;
    width: 246px;
    height: 25px;
    background: #ffffff;
    border: 1px solid var(--dark);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding-left: 20px;
  }

  .svg-check-your-inbox {
    margin-top: 135px;
  }

  .friendly-tips {
    margin-top: 55px;
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    color: var(--dark);
  }

  .svg-magic-link-fail {
    margin-top: 50px;
  }

  .login-failed-container {
    .lines-container {
      margin-top: 99px;
    }
  }
}
</style>
