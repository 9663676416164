<template>
  <div class="gallery">
    <div v-if="!loading" class="gallery-product-header">
      <div class="reference">{{ reference }}</div>
      <div class="name">{{ product ? product.name : 'Product information is not yet available' }}</div>
      <div class="brand">
        <img v-if="brandImage" :src="brandImage" :alt="`${product.brand} logo`" />
      </div>
    </div>

    <div v-if="!loading && !product" class="not-found">
      <div>Product not found... :(</div>
    </div>

    <div v-if="!loading" class="image-container">
      <div class="images">
        <gallery :images="images" :index="index" @close="index = null" />

        <div
          class="image"
          v-for="(image, imageIndex) in thumbnails"
          :key="imageIndex"
          @click="index = imageIndex"
          :style="{
            backgroundImage: 'url(' + image + ')',
            width: '300px',
            height: '300px',
            cursor: 'pointer',
            position: 'relative',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueGallery from 'vue-gallery'

import BackEndRepository from '../repositories/BackEndRepository'
import GetBrandImage from '../services/GetBrandImage'

export default {
  components: { gallery: VueGallery },

  data: () => ({
    loading: true,
    product: null,
    images: [],
    thumbnails: [],
    index: null,
  }),

  computed: {
    brandImage() {
      if (!this.product) return null
      return GetBrandImage(this.product.brand)
    },

    reference() {
      return this.$route.params.reference
    },
  },

  async created() {
    try {
      if (!this.reference) throw new Error('No product reference')
      const backEndRepository = BackEndRepository.init()
      this.product = await backEndRepository.getProduct(this.reference)
      const pictures = this.product.pictures.split(';')
      this.images = pictures.map(image => backEndRepository.getPictureUrl(image))
      this.thumbnails = pictures.map(image => backEndRepository.getThumbnailUrl(image))
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },
}
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.gallery .not-found {
  display: grid;
  height: 100vh;
  align-items: center;
  place-content: center;
  text-align: center;
  font-size: 20px;
  margin-top: 18px;
}

.gallery .not-found div {
  font-size: 36px;
}

.image-container {
  height: 100%;
}

.gallery .images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  column-gap: 16px;
  place-content: center;
}

.gallery-product-header {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  text-align: center;
  padding: 16px 0;
}

.gallery-product-header .brand {
  display: grid;
  place-content: center;
}

.gallery-product-header .reference,
.gallery-product-header .name {
  font-size: 18px;
}

.gallery-product-header .brand img {
  max-width: 80px;
}
</style>
