import discountManagementMigration from './discountManagementMigration'
import newReferencesMigration from './newReferencesMigration'
import KUW0610Migration from './KUW0610Migration'

const migrations = [
  discountManagementMigration, //
  newReferencesMigration, //
  KUW0610Migration,
]

export default migrations
