import ElementUI from 'element-ui'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueLazyload from 'vue-lazyload'
import VueRouter from 'vue-router'
import VueZoomer from 'vue-zoomer'

import i18n from './plugins/language'
import router from './plugins/vueRouter'
import App from './App.vue'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

Vue.use(VueZoomer)
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.use(VueHtmlToPaper, {
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=no'],
  styles: ['/public/css/common.css'],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
})

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 4,
  throttleWait: 500,
})

const vueInstance = new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')

const navLang = navigator.language
const localLang = navLang === 'zh-CN' || navLang === 'en-US' ? navLang : null
vueInstance.$i18n.locale = vueInstance.$store.state.language.language || localLang || 'en-US'
vueInstance.$store.commit('language/setLanguage', vueInstance.$i18n.locale)
