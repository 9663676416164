import WebClient from '../services/WebClient'

class BackEndRepository {
  static init() {
    return new BackEndRepository()
  }

  constructor(webClient) {
    this.client = webClient || WebClient.init()
  }

  async getProducts(user = null, country) {
    if (user?.country) {
      return this.client.post({ route: '/user-products' })
    }
    return this.client.post({ route: '/products', body: { country } })
  }

  async getProduct(shape) {
    return this.client.get({ route: `/products/${shape}` })
  }

  async getClients() {
    return this.client.get({ route: '/clients' })
  }

  async updateUserInfo(userInformation) {
    return this.client.put({
      route: '/user-info',
      body: { userInformation },
      params: { id: userInformation.uid },
    })
  }

  async getUserInfo() {
    return this.client.get({ route: '/user-info' })
  }

  async getColors() {
    return this.client.get({ route: '/colors' })
  }

  async getOrders() {
    return this.client.get({ route: '/orders' })
  }

  async getUserAddresses() {
    return this.client.get({ route: '/addresses' })
  }

  async postAddress(addressPayload) {
    return this.client.post({ route: '/address', body: { addressPayload } })
  }

  async deleteAddressByUid(addressUid) {
    return this.client.delete({ route: '/address', params: { id: addressUid } })
  }

  async postCart(payload) {
    return this.client.post({ route: '/cart', body: { payload } })
  }

  async getOrderItems(orderUid) {
    return this.client.get({ route: '/order-items', params: { orderUid } })
  }

  async getOrderAddresses(orderUid) {
    return this.client.get({ route: '/order-addresses', params: { orderUid } })
  }

  async login(email, password) {
    const token = await this.client.post({ route: '/login', body: { email, password } })
    this.client.setBearerToken(token)
    return token
  }

  async logout() {
    return this.client.get({ route: '/logout' })
  }

  async resetPassword(email) {
    return this.client.get({ route: '/reset-password', params: { email } })
  }

  async setPassword(email, token, password) {
    return this.client.post({ route: '/reset-password', body: { email, token, password } })
  }

  async register(email, password, country) {
    return this.client.post({ route: '/register', body: { email, password, country } })
  }

  async validateEmail(email, token) {
    return this.client.post({ route: '/validate-email', body: { email, token } })
  }

  getPictureUrl(fileName) {
    return `${this.client.baseUrl}/images/products/${fileName}`
  }

  getThumbnailUrl(fileName) {
    return `${this.client.baseUrl}/images/thumbnails/${fileName}`
  }
}

export default BackEndRepository
