import Vue from 'vue'

import { getDiscountInPercentageFromPrices } from '../services/MathService'
import PricesService from '../services/PricesService'

import CartItem from '../models/CartItem'
import Incoterms from '../enums/Incoterms'
import PackingTypes from '../enums/PackingTypes'
import ShipmentModes from '../enums/ShipmentModes'

const defaults = {
  incoterm: Incoterms.EXW,
  shippingAddressUid: '',
  billingAddressUid: '',
  notes: '',
  shipmentMode: ShipmentModes.airNoDispatch,
  packingType: PackingTypes.individual,
}

const state = {
  cartItems: [],
  incoterm: defaults.incoterm,
  shippingAddressUid: defaults.shippingAddressUid,
  billingAddressUid: defaults.billingAddressUid,
  notes: defaults.notes,
  shipmentMode: defaults.shipmentMode,
  packingType: defaults.packingType,

  // These values are hydrated in actions/initialize/updateCartItemsAndPrices
  totalPrice: 0,
  discountOnVolume: 0,
  discountedTotalPrice: 0,
  discountInValue: 0,
  discountInPercentage: 0,
}

const mutations = {
  setPrices(
    state,
    {
      totalPrice, //
      discountOnVolume,
      discountedTotalPrice,
      discountInValue,
      discountInPercentage,
    },
  ) {
    state.totalPrice = totalPrice
    state.discountOnVolume = discountOnVolume
    state.discountedTotalPrice = discountedTotalPrice
    state.discountInValue = discountInValue
    state.discountInPercentage = discountInPercentage
  },

  clearCartItems(state) {
    Vue.set(state, 'cartItems', [])
  },

  setCartItems(state, cartItems) {
    Vue.set(state, 'cartItems', cartItems)
  },

  setIncoterm(state, incoterm) {
    state.incoterm = incoterm
  },

  setShipmentMode(state, shipmentMode) {
    state.shipmentMode = shipmentMode
  },

  setPackingType(state, packingType) {
    state.packingType = packingType
  },

  setShippingAddressUid(state, uid) {
    Vue.set(state, 'shippingAddressUid', uid)
  },

  setBillingAddressUid(state, uid) {
    Vue.set(state, 'billingAddressUid', uid)
  },

  setNotes(state, notes) {
    state.notes = notes
  },
}

const actions = {
  initialize({ commit, state, dispatch, rootState }) {
    if (!state.incoterm) commit('setIncoterm', defaults.incoterm)
    if (!state.shipmentMode) commit('setShipmentMode', defaults.shipmentMode)
    if (!state.packingType) commit('setPackingType', defaults.packingType)
    const activeProductsReferences = rootState.products.products
      .filter(({ active }) => active)
      .map(({ reference }) => reference)

    console.log('🚀 ~ file: cart.js:100 ~ initialize ~ state.cartItems:', state.cartItems)
    const cartItemsWithActiveProductsOnly = state.cartItems.filter(({ shapeReference }) =>
      activeProductsReferences.includes(shapeReference),
    )

    dispatch('updateCartItemsAndPrices', cartItemsWithActiveProductsOnly)
  },

  addCartItem({ state, dispatch }, cartItem) {
    // For some reason, using spread operator here raises a state mutation error :/
    const existingItems = JSON.parse(JSON.stringify(state.cartItems))
    const existingItemIndex = existingItems.findIndex(({ reference }) => reference === cartItem.reference)

    let cartItemsToUpdate
    if (existingItemIndex > -1) {
      const updatedCartItem = CartItem.create(existingItems[existingItemIndex])
      updatedCartItem.quantity = cartItem.quantity += updatedCartItem.quantity
      updatedCartItem.recalculateTotalPrice()
      existingItems[existingItemIndex] = updatedCartItem
      cartItemsToUpdate = existingItems
    } else {
      cartItemsToUpdate = [...existingItems, cartItem]
    }

    dispatch('updateCartItemsAndPrices', cartItemsToUpdate)
  },

  updateCartItem({ state, dispatch }, { oldItemReference, newItem }) {
    const itemsToUpdate = [...state.cartItems]
    const oldItemIndex = itemsToUpdate.findIndex(({ reference }) => reference === oldItemReference)
    const updatedNewItem = CartItem.create(newItem).recalculateTotalPrice()
    itemsToUpdate[oldItemIndex] = updatedNewItem
    dispatch('updateCartItemsAndPrices', itemsToUpdate)
  },

  removeCartItem({ state, dispatch }, cartItem) {
    const itemsToUpdate = [...state.cartItems].filter(({ reference }) => reference !== cartItem.reference)
    dispatch('updateCartItemsAndPrices', itemsToUpdate)
  },

  updateCartItemsAndPrices({ commit, rootState }, cartItemsToUpdate) {
    const pricesService = PricesService.create(rootState.app.user, rootState.products.discountsOnVolume)
    const pricesAndCartItems = pricesService.computePrices(cartItemsToUpdate)
    commit('setCartItems', pricesAndCartItems.cartItems)
    commit('setPrices', pricesAndCartItems)
  },

  reset({ commit }) {
    commit('setIncoterm', defaults.incoterm)
    commit('setShippingAddressUid', defaults.shippingAddressUid)
    commit('setBillingAddressUid', defaults.billingAddressUid)
    commit('setNotes', defaults.notes)
    commit('clearCartItems')
  },
}

const getters = {
  totalSetQuantity: state => {
    return [...state.cartItems].reduce((acc, { quantity }) => (acc += quantity), 0)
  },

  referenceCount: (state, getters) => reference => {
    return getters.itemsByReference(reference).reduce((acc, { quantity }) => (acc += quantity), 0)
  },

  referenceTotalPrice: (state, getters) => reference => {
    return getters.itemsByReference(reference).reduce((acc, { totalPrice }) => (acc += totalPrice), 0)
  },

  referenceDiscountedTotalPrice: (state, getters) => reference => {
    return getters
      .itemsByReference(reference)
      .reduce((acc, { discountedTotalPrice }) => (acc += discountedTotalPrice), 0)
  },

  referenceDiscountInPercentage: (state, getters) => reference => {
    const itemsByReference = getters.itemsByReference(reference)
    if (!itemsByReference.length) return 0
    const { totalPrice, discountedTotalPrice } = itemsByReference[0]
    return getDiscountInPercentageFromPrices(totalPrice, discountedTotalPrice)
  },

  itemsByReference: state => reference => {
    return [...state.cartItems].filter(({ shapeReference }) => shapeReference === reference)
  },

  cartItemsMappedByReference: state => {
    return [...state.cartItems].reduce((acc, cartItem) => {
      const { shapeReference } = cartItem
      if (!acc[shapeReference]) acc[shapeReference] = []
      acc[shapeReference].push(cartItem)
      return acc
    }, {})
  },
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations,
}
