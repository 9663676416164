<template>
  <div class="login-wrapper">
    <img :src="KastlineLogo" alt="Kastline logo" class="kastline-logo" />

    <div v-if="wasEmailSent" class="check-your-inbox-container">
      <img :src="CheckInboxIcon" alt="Check inbox icon" />
      <div class="friendly-tips">{{ $t('Check_your_inbox') }}</div>
    </div>

    <div v-else class="login-input-container mt-40">
      <div class="login-description">
        <div class="headline">
          {{ $t('enter_your_email_to_receive_the_instructions_about_resetting_your_password') }}
        </div>
      </div>

      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-position="top"
        onsubmit="event.preventDefault()"
        @keyup.enter.native="onFormSubmitted"
      >
        <el-form-item prop="email">
          <el-input v-model="loginForm.email" :placeholder="$t('Email')" />
        </el-form-item>
      </el-form>

      <div class="call-to-action" @click="onFormSubmitted()">{{ $t('reset_password') }}</div>
      <div class="secondary-button mt-30" @click="$router.push({ path: '/password-login' })">
        {{ $t('go_back_to_login') }}
      </div>
    </div>
  </div>
</template>

<script>
import KastlineLogo from '../../public/images/logo/kastline_logo.png'
import CheckInboxIcon from '../../public/images/icons/check-inbox.svg'

import { emailPattern } from '../services/patterns'

export default {
  data() {
    return {
      KastlineLogo,
      CheckInboxIcon,

      wasEmailSent: false,

      loginForm: {
        email: '',
      },

      rules: {
        email: [{ required: true, message: 'Please input a valid email', pattern: emailPattern, trigger: 'blur' }],
      },
    }
  },

  methods: {
    async onFormSubmitted() {
      this.$refs['loginForm'].validate(async valid => {
        try {
          if (valid) {
            await this.$store.dispatch('app/resetPassword', this.loginForm.email)
            this.wasEmailSent = true
          } else {
            throw new Error('Something went wrong...')
          }
        } catch (error) {
          console.error(error)
        }
      })
    },
  },
}
</script>
<style lang="scss">
.login-wrapper,
.login-input-container,
.login-failed-container,
.check-your-inbox-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-wrapper {
  .kastline-logo {
    margin-top: 50px;
    max-width: 290px;
  }

  .svg-magic {
    margin-top: 70px;
  }

  .lines-container {
    margin-top: 30px;

    div {
      width: 206px;
      height: 17px;
      background: var(--lighter);
      margin-bottom: 6px;
    }
  }

  input {
    margin-top: 0px;
    width: 246px;
    height: 25px;
    background: #ffffff;
    border: 1px solid var(--dark);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding-left: 20px;
  }

  .svg-check-your-inbox {
    margin-top: 135px;
  }

  .friendly-tips {
    margin-top: 55px;
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    color: var(--dark);
  }

  .svg-magic-link-fail {
    margin-top: 50px;
  }

  .login-failed-container {
    .lines-container {
      margin-top: 99px;
    }
  }
}
</style>
