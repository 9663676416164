<template>
  <div class="product-card-outer-wrapper">
    <div class="product-card-inner-wrapper">
      <div class="product-card-header">
        <div class="product-name">{{ product.name }}</div>

        <div class="select-index-block" v-show="theOrderedCount">
          <div>{{ theOrderedCount }}</div>
          <div>{{ theOrderedCount > 1 ? ` ${$t('Sets')}` : ` ${$t('Set')}` }}</div>
        </div>
      </div>

      <div class="product-card-body">
        <div class="product-card-body-image-section">
          <product-image :fileName="picture" :clickable="true" />
        </div>

        <div class="product-card-body-description-section">
          <img class="product-brand" :src="GetBrandImage(product.brand)" alt="" />
          <div class="size">{{ $t('Size') }} {{ product.size }} {{ grips.length ? `- ${grips}` : '' }}</div>
          <product-prices :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import GetBrandImage from '../services/GetBrandImage'
import ProductImage from './ProductImage'
import ProductPrices from './ProductPrices.vue'
import { gripsFromShapeTypes } from '../services/formatting'

export default {
  components: { ProductImage, ProductPrices },

  data: () => ({ GetBrandImage }),

  computed: {
    ...mapGetters({
      referenceCount: 'cart/referenceCount',
      product: 'app/getSelectedProduct',
    }),

    ...mapState({
      colors: state => state.products.colors,
    }),

    theOrderedCount() {
      return this.referenceCount(this.product.reference)
    },

    grips() {
      return gripsFromShapeTypes(this.product.shapeTypes)
    },

    picture() {
      return this.product.pictures[0]
    },
  },
}
</script>

<style lang="scss">
.product-card-outer-wrapper {
  display: grid;
  place-items: center;

  .product-card-inner-wrapper {
    width: 330px;
    margin: 20px 10px 10px 10px;
    display: grid;
    position: relative;
    grid-template-rows: auto auto;
    background: white;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 24px 25px 34px 34px;

    .product-card-header {
      background-color: var(--light);
      box-sizing: border-box;
      height: 35px;
      background: var(--light);
      border-radius: 24px 25px 0 0;
      padding: 0 17px;
      font-size: var(--medium);
      font-weight: bold;
      color: var(--white);
      text-align: center;

      .product-name {
        height: 100%;
        display: grid;
        align-content: center;
      }
    }

    .product-card-body {
      display: grid;
      position: relative;
      grid-template-columns: 140px 1fr;

      .product-card-body-image-section {
        width: 120px;
        height: 120px;
        padding: 10px;
        .image-container {
          width: 120px;
          height: 120px;
        }
      }

      .product-card-body-description-section {
        text-align: center;
        display: grid;
        justify-items: center;
        align-items: center;

        .product-brand {
          width: 70px;
        }
      }
    }
  }
}
</style>
