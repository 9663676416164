import { formatPriceAsString } from '../services/formatting'
import { getMaterialNameFromMaterialCode } from '../services/Materials'
import TrigramToBrand from '../enums/TrigramToBrand'

class OrderItemsView {
  static create(products = [], colors = []) {
    return new OrderItemsView(products, colors)
  }

  constructor(products, colors) {
    this.products = products
    this.colors = colors
  }

  getFromOrderItems(orderItems) {
    return orderItems.map(item => {
      return {
        reference: item.reference,
        color: this.getColor(item),
        name: this.getName(item),
        brand: this.getBrand(item),
        material: getMaterialNameFromMaterialCode(this.getMaterial(item)),
        quantity: item.quantity,
        unitPrice: formatPriceAsString(item.unitPrice, item.currency),
        totalPrice: formatPriceAsString(item.totalPrice, item.currency),
        discount: formatPriceAsString(item.discount, item.currency),
        discountedTotalPrice: formatPriceAsString(item.discountedTotalPrice, item.currency),
      }
    })
  }

  getName(item) {
    const product = this.products.find(({ reference }) => reference === item.shapeReference)
    if (!product) return 'N/A'
    return product.name
  }

  getColor(item) {
    const colorReference = item.reference.split('-').pop()
    const color = this.colors.find(({ reference }) => reference === colorReference)
    if (!color) return 'N/A'
    return color.name
  }

  getBrand(item) {
    const trigram = item.reference.substring(0, 3)
    return TrigramToBrand[trigram] || 'N/A'
  }

  getMaterial(item) {
    return item.reference.split('-')[1]
  }
}

export default OrderItemsView
