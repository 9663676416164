<template>
  <div v-show="showImagePreview" class="product-image-previewer-wrapper">
    <div :class="[imageContainerClass]">
      <VZoomer style="width: 100vw; height: 100vh">
        <img :src="previewImageUrl" @load="imageContainerClass = ''" @error="imageContainerClass = 'error-image'" />
        <Loading v-if="imageContainerClass == 'loading-image'" />
      </VZoomer>
      <i class="el-icon-close" @click="setImagePreviewHide"></i>
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    Loading,
  },
  data: () => {
    return {
      imageContainerClass: 'loading-image',
    }
  },
  methods: {
    ...mapMutations({
      setImagePreviewHide: 'image/setImagePreviewHide',
    }),
  },
  computed: {
    ...mapState({
      showImagePreview: state => state.image.showImagePreview,
      previewImageUrl: state => state.image.previewImageUrl,
    }),
  },
}
</script>

<style lang="scss">
.product-image-previewer-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 1000;
  left: 0;
  top: 0;

  img {
    background-color: white;
    width: 100%;
  }

  .zoomer {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 800px;
    }
  }

  .el-icon-close {
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--lighter);
    color: black;
    text-align: center;
    line-height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
    font-weight: 900;
    font-size: 32px;
  }

  .error-image::after {
    width: 100%;
  }
}
</style>
