<template>
  <img :src="incotermsReference" style="width: 100%" />
</template>

<script>
import incotermsReference from '../../public/images/incoterms.png'

export default {
  data() {
    return {
      incotermsReference,
    }
  },
}
</script>
