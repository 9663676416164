import Filters from './Filters'

const FilterToField = {
  [Filters.material]: 'materials',
  [Filters.shape]: 'shapeTypes',
  [Filters.size]: 'size',
  [Filters.brand]: 'brand',
  [Filters.texture]: 'texture',
}

export default FilterToField
