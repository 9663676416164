<template>
  <div class="product-detail-set-wrapper">
    <div class="product-detail-set-header">
      <span class="set-index">{{ $t('Set') }} {{ setIndex + 1 }}</span>
      <span class="set-actions">
        <span class="clickable" @click="onModifyClicked(theSet)">{{ $t('UPDATE') }}</span>
        <i class="el-icon-close clickable" @click="toggleShowRemoveConfirm(true)"></i>
      </span>
    </div>
    <div class="set-detail-body">
      <div class="set-color-chip" :style="getColorBoxStyle(theSet.color)"></div>
      <div class="set-properties">
        <div>{{ $t('Material') }}: {{ getMaterialNameFromMaterialCode(theSet.material) }}</div>
        <div>{{ $t('Quantity') }}: {{ theSet.quantity }} {{ $t('units') }}</div>
        <div>Color: {{ colorRefToName(theSet.color) }}</div>
      </div>
      <div class="set-price">
        <cart-item-prices :cart-item-dto="theSet" :discounted-price-only="false" />
      </div>
    </div>
    <div class="set-detail-footer">
      <div class="set-removal-confirmation" v-show="showRemoveConfirm">
        <span class="set-removal-confirmation-text">Remove the set?</span>
        <div class="set-removal-confirmation-buttons">
          <span class="clickable" @click="removeCartItem(theSet)">REMOVE</span>
          <span class="clickable" @click="toggleShowRemoveConfirm(false)">CANCEL</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { getMaterialNameFromMaterialCode } from '../services/Materials'

import CartItem from '../models/CartItem'
import CartItemPrices from './CartItemPrices.vue'
import GetBrandImage from '../services/GetBrandImage'

export default {
  props: ['set', 'set-index'],

  components: { CartItemPrices },

  data: () => {
    return {
      GetBrandImage,
      showRemoveConfirm: false,
      getMaterialNameFromMaterialCode,
    }
  },

  computed: {
    ...mapState({
      colors: state => state.products.colors,
    }),

    theSet() {
      return CartItem.create(this.set)
    },
  },

  methods: {
    ...mapActions({
      removeCartItem: 'cart/removeCartItem',
    }),

    toggleShowRemoveConfirm(showRemoveConfirm) {
      this.showRemoveConfirm = showRemoveConfirm
    },

    colorRefToName(ref) {
      return this.colors.find(({ reference }) => reference === ref).name
    },

    getColorBoxStyle(colorReference) {
      const cssColor = this.colorRefToCssColor(colorReference)
      const style = { backgroundColor: cssColor }
      if (cssColor === '#fff') style.boxShadow = `inset 0px 0px 0px 1px grey`
      return style
    },

    colorRefToCssColor(ref) {
      return this.colors.find(({ reference }) => reference === ref).cssColor
    },

    onModifyClicked(item) {
      this.$router.push({
        path: `/product/${item.shapeReference}/edit`,
        query: {
          color: item.color,
          material: item.material,
          quantity: item.quantity,
          reference: item.reference,
          update: true,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.product-detail-set-wrapper {
  display: grid;
  grid-auto-flow: row;

  .product-detail-set-header {
    font-weight: bold;
    font-size: var(--medium);
    width: 100%;
    height: 23px;
    background: var(--light);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 3px;
    display: flex;
    line-height: 23px;
    text-align: left;
    padding: 0 14px 0 19px;
    box-sizing: border-box;
    position: absolute;
    z-index: 1;

    .set-index {
      display: inline-block;
      width: 100%;
      color: white;
      font-weight: bold;
    }

    .set-actions {
      display: inline-block;
      width: 80px;
      white-space: nowrap;
      color: var(--primary);

      span {
        margin-right: 10px;
      }

      i {
        font-weight: bold !important;
        margin-left: 4px;
      }
    }
  }

  .set-detail-body {
    padding: 23px 13px 0 48px;
    position: relative;
    display: grid;
    grid-auto-flow: column;

    .set-color-chip {
      width: 35px;
      height: 101px;
      background: #ff0000;
      opacity: 1;
      border-radius: 0 0 18px 18px;
      position: absolute;
      top: 0;
      left: 13px;
      flex-shrink: 0;
    }

    .set-properties {
      margin-top: 13px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: var(--dark);
      width: 100%;
      text-align: left;
      margin-left: 18px;

      div {
        margin-bottom: 7px;
      }
    }

    .set-price {
      color: var(--dark);
      position: relative;
      display: inline-block;
      margin-top: 18px;
      text-align: center;
    }
  }

  .set-detail-footer {
    height: 23px;

    .set-removal-confirmation {
      height: 23px;
      display: grid;
      align-items: center;
      text-align: left;
      font-weight: bold;
      color: var(--secondary);
      grid-template-columns: 1fr 1fr;
      font-size: 12px;

      .set-removal-confirmation-text {
        font-weight: bold;
        text-align: right;
      }

      .set-removal-confirmation-buttons {
        margin-right: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        justify-self: end;
        grid-gap: 10px;

        span:nth-child(1) {
          border: 1px solid var(--secondary);
          border-radius: 11px;
          display: inline-block;
          padding: 0 8px;
          line-height: 22px;
          height: 22px;
        }
        span:nth-child(2) {
          border: 1px solid var(--lighter);
          color: var(--lighter);
          border-radius: 11px;
          display: inline-block;
          padding: 0 8px;
          line-height: 22px;
          height: 22px;
        }
      }
    }
  }
}
</style>
