import { getTotalPriceInCents } from '../services/MathService'

const migrateCartItems = (cartItems, currency) => {
  if (!cartItems) return []

  if (cartItems instanceof Array) return cartItems

  const cartItemsAsArray = Object.values(cartItems).flat()

  return cartItemsAsArray.map(cartItem => {
    const unitPrice = cartItem.prices.price
    const { quantity } = cartItem
    return {
      currency,
      discount: 0,
      discountedTotalPrice: 0,
      parentReference: cartItem.parentReference,
      quantity: quantity,
      reference: cartItem.reference,
      shapeReference: cartItem.shapeReference,
      totalPrice: getTotalPriceInCents(quantity, unitPrice),
      unitPrice,
    }
  })
}

const migrateUser = user => {
  if (user.exclusiveDistributorOf) return user
  return { ...user, exclusiveDistributorOf: [] }
}

const discountManagementMigration = {
  version: 12,
  up: state => {
    try {
      return {
        ...state,
        cart: {
          ...state.cart,
          cartItems: migrateCartItems(state.cart.cartItems, state.products.pricelist.currency),
        },
        app: {
          ...state.app,
          version: 12,
          user: migrateUser(state.app.user),
        },
      }
    } catch (error) {
      console.error(error)
    }
  },
}

export default discountManagementMigration
