<template>
  <div class="login-wrapper">
    <img :src="KastlineLogo" alt="Kastline logo" class="kastline-logo" />
    <div class="login-input-container mt-40">Email verification...</div>
  </div>
</template>

<script>
import KastlineLogo from '../../public/images/logo/kastline_logo.png'

export default {
  data() {
    return {
      KastlineLogo,
    }
  },

  async created() {
    try {
      const { email, token } = this.$route.params
      await this.$store.dispatch('app/validateEmail', { email, token })
      this.$router.push({ path: '/password-login' })
    } catch (error) {
      console.error(error)
    }
  },
}
</script>
<style lang="scss">
.login-wrapper,
.login-input-container,
.login-failed-container,
.check-your-inbox-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-wrapper {
  .kastline-logo {
    margin-top: 50px;
    max-width: 290px;
  }

  .svg-magic {
    margin-top: 70px;
  }

  .lines-container {
    margin-top: 30px;

    div {
      width: 206px;
      height: 17px;
      background: var(--lighter);
      margin-bottom: 6px;
    }
  }

  input {
    margin-top: 0px;
    width: 246px;
    height: 25px;
    background: #ffffff;
    border: 1px solid var(--dark);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding-left: 20px;
  }

  .svg-check-your-inbox {
    margin-top: 135px;
  }

  .friendly-tips {
    margin-top: 55px;
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    color: var(--dark);
  }

  .svg-magic-link-fail {
    margin-top: 50px;
  }

  .login-failed-container {
    .lines-container {
      margin-top: 99px;
    }
  }
}
</style>
