<template>
  <div class="top-menu-wrapper" v-show="visible">
    <div class="fixed-top-menu">
      <img src="../../public/images/svg/icon-menu.svg" @click="toggleExtendPanel" />
      <img @click="toOtherPage({ path: '/products' })" src="../../public/images/svg/icon-logo.svg" />
      <a href="/cart">
        <div class="cart-icon">
          <div v-if="totalSetQuantity" class="ordered-quantity-chip">{{ totalSetQuantity }}</div>
          <img src="../../public/images/svg/icon-cart.svg" @click="toOtherPage({ path: '/cart' })" />
        </div>
      </a>
    </div>

    <transition name="el-fade-in-linear">
      <div class="extend-menu-panel" v-show="showExtendPanel">
        <div class="close-button-wrap">
          <i @click="toggleExtendPanel" class="el-icon-close"></i>
        </div>
        <div class="menu-items-outer">
          <div class="menu-items-inner">
            <div @click="toOtherPage({ path: '/products' })">{{ $t('catalogue') }}</div>

            <div v-if="user && !userCountry" @click="toOtherPage({ path: '/orders' })">{{ $t('my_orders') }}</div>

            <div v-if="user && !userCountry" @click="toOtherPage({ path: '/my-information' })">
              {{ $t('my_information') }}
            </div>
            <div @click="toOtherPage({ path: '/cart' })">{{ $t('my_cart') }}</div>

            <div v-if="!!discountsOnVolume.length" @click="toOtherPage({ path: '/discount-policy' })">
              {{ $t('discount_policy') }}
            </div>

            <div v-if="!user">{{ $t('change_country') }}</div>

            <div class="switch-language" v-if="!user">
              <el-form :model="userCountryForm" :rules="rules" ref="userCountryForm" label-position="top">
                <el-form-item label-width="120px" prop="country">
                  <el-select
                    v-model="userCountryForm.country"
                    filterable
                    :no-match-text="$t('No_matching_result')"
                    :placeholder="$t('Choose_a_country')"
                    autocomplete="disabled"
                    style="width: 100%"
                    @change="onFormValidation"
                  >
                    <el-option v-for="code in CountryCodes" :key="`c${code}`" :label="$t(code)" :value="code" />
                  </el-select>
                </el-form-item>
              </el-form>
            </div>

            <a :href="VUE_APP_CRM_URL" target="_blank" rel="noopener noreferrer">
              <div v-if="showCrmButton">{{ $t('CRM') }}</div>
            </a>

            <div v-if="!userCountry" @click="logout()">Logout</div>
            <div v-if="userCountry" @click="$router.push({ path: '/welcome' })">Login</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const { VUE_APP_CRM_URL } = process.env

import { mapGetters, mapState } from 'vuex'
import CountryCodes from '../enums/CountryCodes'
import UserTypes from '../enums/UserTypes'

export default {
  components: {},
  data: () => {
    return {
      CountryCodes,

      userCountryForm: {
        country: '',
      },

      showExtendPanel: false,
      VUE_APP_CRM_URL,

      rules: {
        country: [
          { required: true, message: 'Please input your country', trigger: 'blur' },
          { min: 2, max: 100, message: 'The country should be 2 to 100 characters long', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.app.user,
      userCountry: state => state.app.userCountry,
      discountsOnVolume: state => state.products.discountsOnVolume,
    }),
    ...mapGetters({
      totalSetQuantity: 'cart/totalSetQuantity',
    }),
    routerMeta() {
      return this.$route.meta
    },
    visible() {
      return this.routerMeta.needUserCountry || this.routerMeta.protected
    },
    showCrmButton() {
      return [UserTypes.admin, UserTypes.sales].includes(this.user?.type)
    },
  },
  methods: {
    async onFormValidation() {
      this.$refs['userCountryForm'].validate(async valid => {
        try {
          if (!valid) return
          this.$store.dispatch('app/resetState')
          this.$store.commit('app/setUserCountry', this.userCountryForm.country)
          await this.$store.dispatch('products/initialize')
          this.showExtendPanel = false
        } catch (error) {
          console.error(error)
        }
      })
    },

    toggleExtendPanel() {
      this.showExtendPanel = !this.showExtendPanel
    },

    async logout() {
      try {
        if (!this.user || !this.user.country) {
          this.$store.dispatch('app/resetState')
          this.$router.push({ path: '/welcome' })
          return
        }

        await this.$store.dispatch('app/logout')
        this.toOtherPage({ path: '/welcome' })
      } catch (error) {
        console.error(error)
      }
    },

    toOtherPage(param) {
      this.hideExtendPanel()
      this.$router.push(param)
    },

    hideExtendPanel() {
      this.showExtendPanel = false
    },
  },
  async created() {
    if (this.userCountry) {
      this.userCountryForm.country = this.userCountry
    }
  },
}
</script>
<style lang="scss">
.top-menu-wrapper {
  box-sizing: border-box;
  background: #ffffff;
  width: 100%;
  height: var(--top-menu-height);

  .fixed-top-menu {
    background: #ffffff;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.16);

    img {
      cursor: pointer;
    }

    .cart-icon {
      .ordered-quantity-chip {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: var(--accent);
        color: white;
        font-weight: bold;
        position: absolute;
        top: 5px;
        right: 3px;
        text-align: center;
        display: grid;
        place-content: center;
      }
    }
  }

  .extend-menu-panel {
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-color: white;
    z-index: 98;
    position: relative;
    height: calc(100vh - var(--top-menu-height));
    width: 100%;

    .close-button-wrap {
      height: 42px;
      display: flex;
      justify-self: right;
      place-items: flex-end;
      margin-right: 20px;

      .el-icon-close {
        cursor: pointer;
        position: absolute;
        right: 16px;
        color: var(--lighter);
        font-size: 27px;
        font-weight: bolder;
      }
    }
  }

  .menu-items-outer {
    display: grid;
    place-items: center;

    .menu-items-inner {
      display: grid;
      align-items: center;
      grid-auto-rows: minmax(90px, 1fr);
      font-size: 25px;
      font-weight: bold;
      width: 100%;
      text-align: center;

      div {
        cursor: pointer;
        font-size: var(--big);
        text-transform: capitalize;
      }
    }
  }

  .switch-language {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 80px;

    span:nth-child(1) {
      color: var(--lighter);
    }

    img {
      margin: 0 10px 0 5px;
    }
  }
}
</style>
