<template>
  <div :class="['product-modal-wrapper', Object.keys(cartItems).length >= 3 ? 'need-padding-bottom' : '']">
    <div class="cart-item-header">
      <span>{{ $t(product.brand) }}</span>
      <span>{{ product.name }}</span>
      <span class="modify-button" v-if="editable" @click="toDetail">{{ $t('UPDATE') }}</span>
      <span v-else>&nbsp;</span>
    </div>

    <div class="product-main-container">
      <product-image :fileName="picture" :clickable="true" />

      <div class="cart-items-body">
        <div class="cart-items-description">
          <span>{{ $t('Ordered_sets') }}:</span>
        </div>

        <div class="cart-items-container">
          <div class="cart-item" :key="index" v-for="(item, index) in cartItems">
            <span> {{ getMaterialNameFromMaterialCode(item.material) }} </span>
            <span class="item-color" :style="getColorBoxStyle(item.color)">&nbsp;</span>
            <span>{{ item.quantity }} {{ item.quantity > 1 ? 'units' : 'unit' }}</span>
            <cart-item-prices :cart-item-dto="item" :discounted-price-only="true" />
          </div>
        </div>

        <span class="cart-item-total">
          <span>{{ $t('Total') }}:</span>
          <cart-item-total-prices :cart-items="cartItems" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { getMaterialNameFromMaterialCode } from '../services/Materials'

import CartItemPrices from './CartItemPrices.vue'
import CartItemTotalPrices from './CartItemTotalPrices.vue'
import ProductImage from './ProductImage.vue'
import CartItem from '../models/CartItem'

export default {
  components: {
    CartItemPrices,
    CartItemTotalPrices,
    ProductImage,
  },

  data: () => ({
    getMaterialNameFromMaterialCode,
  }),

  props: ['cartItemsDto', 'editable'],

  methods: {
    toDetail() {
      this.$router.push({ path: `/product/${this.product.reference}` })
    },

    colorRefToCssColor(ref) {
      return this.colors.find(({ reference }) => reference === ref).cssColor
    },

    getColorBoxStyle(colorReference) {
      const cssColor = this.colorRefToCssColor(colorReference)
      const style = { backgroundColor: cssColor }
      if (cssColor === '#fff') style.boxShadow = `inset 0px 0px 0px 1px grey`
      return style
    },
  },

  computed: {
    cartItems() {
      return this.cartItemsDto.map(CartItem.create)
    },

    ...mapState({
      colors: state => state.products.colors,
    }),

    ...mapGetters({
      productByReference: 'products/getProductByReference',
    }),

    product() {
      return this.productByReference(this.cartItems[0].shapeReference)
    },

    picture() {
      return this.product.pictures[0]
    },
  },
}
</script>
<style lang="scss">
.modify-button {
  cursor: pointer;
}

.product-modal-wrapper {
  width: 355px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 24px 25px 34px 34px;
  margin-bottom: 20px;
  overflow: hidden;

  .cart-item-header {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    background: var(--light);
    border-radius: 24px 25px 0 0;
    padding: 0 17px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    font-size: var(--medium);
    font-weight: bold;
    color: var(--white);

    span:nth-child(2) {
      text-align: center;
    }

    span:last-child {
      text-align: right;
      color: var(--primary);
    }
  }

  .product-main-container {
    width: 100%;
    display: grid;
    grid-template-columns: 140px 1fr;
    align-items: center;

    .image-container {
      display: flex;
      align-items: center;
      width: 100%;

      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  .cart-items-body {
    display: grid;
    grid-auto-flow: row;
    row-gap: 10px;
    box-sizing: border-box;
    width: 100%;
    align-self: stretch;
    padding: 0 10px 10px 0;
  }

  .cart-items-description {
    width: 100%;
    font-size: var(--medium);
    justify-content: space-between;
    padding-top: 10px;
  }

  .cart-item-total {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
    color: var(--dark);
    text-align: left;
    align-self: self-end;
    font-weight: bold;

    div:last-child {
      text-align: right;
    }
  }

  .cart-item {
    font-size: var(--medium);
    font-weight: 400;
    color: var(--dark);
    display: grid;
    grid-template-columns: 34px 34px 60px auto;
    align-items: baseline;

    span {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span:nth-child(1) {
      width: 30px;
    }

    .item-color {
      width: 27px;
      height: 10px;
      background: #2673e6;
      display: inline-block;
      margin-right: 8px;
    }

    span:nth-child(3) {
      width: 50px;
    }

    span:last-child {
      flex-shrink: 0;
      width: 40%;
      text-align: right;
    }
  }
}

.cart-items-container {
  display: grid;
  grid-auto-flow: row;
  row-gap: 3px;
  text-align: right;

  span:first-child {
    text-align: left;
  }
}
</style>
